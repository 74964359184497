import React, { useState, useContext, useEffect } from 'react'
import ProductContext from '../../Product/context/product/productContext'
import AlertContext from '../../Product/context/alert/alertContext'

const Favourite = ({ watchlisted, project_id, ...props }) => {
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const [projectChanged, setProjectChanged] = useState('')
    const [checked, setChecked] = useState(parseInt(watchlisted) > 0 ? true : false)

    const { addWatchlist, removeWatchlist, responseStatus, clearResponse } = productContext
    useEffect(() => {
        setChecked(parseInt(watchlisted) > 0 ? true : false)
    }, [project_id, watchlisted])
    useEffect(() => {
        if (responseStatus && responseStatus !== '' && project_id === projectChanged) {
            if (responseStatus === 'watchlistadded_successfully') {
                setAlert(
                    global.storeDetails?.theme === 20 ? 'Watchlist Added' : 'Favorite Added',
                    'success',
                )
                clearResponse()
                setChecked(!checked)
            } else if (responseStatus === 'watchlistremoved_successfully') {
                setAlert('Favorite Removed', 'success')
                clearResponse()
                setChecked(!checked)
            } else {
                setAlert(responseStatus, 'error')
                clearResponse()
            }
            setProjectChanged('')
        } else {
            clearResponse()
        }
    }, [responseStatus])

    const toggleFavourite = () => {
        if (checked) {
            setProjectChanged(project_id)
            removeWatchlist({ product_id: project_id })
        } else {
            setProjectChanged(project_id)
            addWatchlist({ product_id: project_id, watchlistemail: '1' })
        }
    }

    return (
        <>
            <div className="wl-cbox" onClick={() => toggleFavourite()}>
                <input type="checkbox" checked={checked} />
                <i className="fa fa-heart"></i>
            </div>
        </>
    )
}

export default Favourite
