import React, { useEffect, useState, useContext } from 'react'
import './NewsLetter.css'
import UserContext from '../../../Product/context/user/userContext'
import AuthContext from '../../../Product/context/auth/authContext'
const NewsLetter = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const userContext = useContext(UserContext)
    const { unRegisterUserSubscribe } = userContext
    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext
    useEffect(() => {
        if (global.storeConfigration?.show_mailchimp_modal?.value == '1') {
            setIsOpen(true)
        }
    }, [])
    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
    const handleClose = () => {
        setIsOpen(false)
    }
    const handleSubscribe = () => {
        if (email.trim() === '') {
            setError('Email is required')
            return
        }
        if (!isValidEmail(email)) {
            setError('Enter a valid email address')
            return
        }
        setError('')
        localStorage.setItem('newsletterSubscribed', 'true')
        setIsOpen(false)
        const dataFroSubscribe = {
            email: email,
            site_id: global?.storeDetails?.site_id,
        }
        unRegisterUserSubscribe(dataFroSubscribe)
    }
    return (
        isOpen &&
        global.storeConfigration?.show_mailchimp_modal?.value == '1' && (
            <div className="overlay newsOverlay">
                <div className="newsletterpop popup">
                    <button className="close-btn" onClick={handleClose}>
                        <span className="material-icons">close</span>
                    </button>
                    <div className="icon">
                        <span className="material-icons">mail</span>
                    </div>
                    <h2 className="title">Newsletter</h2>
                    <p className="subtitle">Stay up to date with our latest news and products</p>
                    <div className="input-container">
                        <input
                            type="email"
                            placeholder="abc@gmail.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button onClick={handleSubscribe}>Subscribe</button>
                    </div>
                    {error && <p className="error-text">{error}</p>}
                    <p className="footer-text">Your email is safe with us, we don&apos;t spam.</p>
                </div>
            </div>
        )
    )
}

export default NewsLetter
