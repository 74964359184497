import React, { useState, useEffect, useContext, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import AllProducts from '../../../Component/MyBids/AllProducts'
import { useLocation, useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../../Product/common/components'
import { commonPaths } from '../../../utils/commonFunctions'
import StripeCustomerContext from '../../../Product/context/stripe/customer/customerContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import UserActivity from '../../../utils/CommonFunctionality/UserActivity'
import buyerContext from '../../../Product/context/buyer/buyerContext'
import axios from 'axios'
import { socket } from '../../../Product/common/socket'
import OrderPickup from '../../../Component/MyBids/OrderPickup'
import Layout from '../Layout'
import DashboardLayout from '../DashboardLayout'
const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            className="customTabsInner dashboard-tabs"
            // component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const PaidInvoices = (props) => {
    const history = useHistory()
    const search = useLocation().search
    const params = new URLSearchParams(search)
    const [value, setValue] = useState(
        global.storeConfigration?.disabled_auc_mulsel2?.value == 1
            ? 3
            : params.get('s')
            ? parseInt(params.get('s'))
            : 0,
    )
    const [valueWon, setValueWon] = useState(params.get('w') ? parseInt(params.get('w')) : 0)
    const [valueBuy, setValueBuy] = useState(params.get('b') ? parseInt(params.get('b')) : 0)
    const { t } = useTranslation()
    const { clearBuyerState, buyer_allproducts, updateVideoCount } = useContext(buyerContext)

    const authContext = useContext(AuthContext)

    const { user, loadUser, isAuthenticated } = authContext

    const {
        createStripeCustomer,
        addCustomerProfile,
        responseStatus: responseStatusStripeCustomer,
    } = useContext(StripeCustomerContext)
    const buyerAllProductsRef = useRef(buyer_allproducts)
    useEffect(() => {
        buyerAllProductsRef.current = buyer_allproducts
    })

    const handleChangeWon = (event, newValue) => {
        clearBuyerState()
        handleRedirectInternal(history, `${commonPaths.pickups.substring(1)}?s=1&w=${newValue}`)
    }

    /*eslint-disable*/
    useEffect(() => {
        if (global.storeConfigration?.disabled_auc_mulsel2?.value == 1) {
            setValue(0)
        } else {
            setValue(params.get('s') ? parseInt(params.get('s')) : 0)
        }
        if (params.get('w')) {
            setValueWon(params.get('w') ? parseInt(params.get('w')) : 0)
        }
        if (params.get('b')) {
            setValueBuy(params.get('b') ? parseInt(params.get('b')) : 0)
        }
    }, [window.location.search])
    console.log(value, 'value+++++++++++++')

    const cancelToken = axios.CancelToken.source()
    const [cancelRequest, setCancelRequest] = useState(undefined)
    useEffect(() => {
        if (
            user &&
            !user.card_paymentid &&
            global.storeConfigration?.credit_card_no_need?.value == 1 &&
            global.storeConfigration?.stripe_integration?.value !== '1' &&
            global.storeConfigration?.enable_cardknox !== '1'
        ) {
            createStripeCustomer({
                account_id: process.env.REACT_APP_AUCTIONPAYID,
                description: 'TEST CUSTOMER',
                email: user.email,
                name: user.first_name + ' ' + user.last_name,
            })
        }
    }, [user])

    useEffect(() => {
        if (
            responseStatusStripeCustomer &&
            global.storeConfigration?.credit_card_no_need?.value == 1 &&
            global.storeConfigration?.enable_cardknox !== '1'
        ) {
            if (responseStatusStripeCustomer.status === 'success') {
                if (responseStatusStripeCustomer.from === 'create') {
                    //console.log('responseStatusStripeCustomer', responseStatusStripeCustomer)
                    addCustomerProfile({
                        customer_id: responseStatusStripeCustomer.data.id,
                    })
                }
                loadUser()
                // setIsLoading(false)
            }
        }
    }, [responseStatusStripeCustomer])

    return (
        <Layout props={props}>
            <DashboardLayout title="Paid Invoices">
                <div>
                    <Fragment>
                        <AllProducts
                            page="bidwon"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            storeTheme={props.storeTheme}
                            redirect_product_view={props.redirect_product_view}
                            paid={1}
                            searchbar={true}
                            searchbarInputNew={true}
                            sortby={true}
                            hideReturn={true}
                            hideCheckout={true}
                        />
                    </Fragment>
                </div>
            </DashboardLayout>
        </Layout>
    )
}

export default PaidInvoices
