import React, { useContext, useEffect, useState, useRef } from 'react'
import { mapData, converDate } from '../../../common/components'
import PrimaryButton from '../../atoms/PrimaryButton'
import { Button } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomDialog from '../Dialog'

import StripeCardContext from '../../../context/stripe/card/cardContext'
import StripeCustomerContext from '../../../context/stripe/customer/customerContext'
import AuthContext from '../../../context/auth/authContext'
// import CustomLoader from './../../molecules/Loaders/CustomLoader'
import AlertContext from '../../../context/alert/alertContext'
import Popup from '../Popup'
import axios from 'axios'
import skyflowClient from '../../../common/skyflowVault'
import Skyflow from 'skyflow-js'
import { swapCountry } from '../../../../utils/commonFunctions'

const container = skyflowClient.container(Skyflow.ContainerType.COLLECT)

const options = {
    required: true,
}

const cardNumberElement = container.create(
    {
        table: process.env.REACT_APP_SKYFLOW_TABLE,
        column: 'card_number',
        inputStyles: {
            base: {
                border: '1px solid #eae8ee',
                padding: '10px 16px',
                borderRadius: '4px',
                color: '#1d1d1d',
                height: '50px',
            },
            complete: {
                color: '#4caf50',
            },
            empty: {},
            focus: {},
            invalid: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        labelStyles: {
            base: {
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: 'Arial',
                marginBottom: '10px',
                color: '#7b7b7b',
            },
        },

        errorTextStyles: {
            base: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        placeholder: 'Card Number',
        label: 'Card Number',
        type: Skyflow.ElementType.CARD_NUMBER,
    },
    options,
)

const cardHolderElement = container.create(
    {
        table: process.env.REACT_APP_SKYFLOW_TABLE,
        column: 'cardholder_name',
        inputStyles: {
            base: {
                border: '1px solid #eae8ee',
                padding: '10px 16px',
                borderRadius: '4px',
                color: '#1d1d1d',
                height: '50px',
            },
            complete: {
                color: '#4caf50',
            },
            empty: {},
            focus: {},
            invalid: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        labelStyles: {
            base: {
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: 'Arial',
                marginBottom: '10px',
                color: '#7b7b7b',
            },
        },
        errorTextStyles: {
            base: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        placeholder: 'Card Holder name',
        label: 'Card Holder Name',
        type: Skyflow.ElementType.CARDHOLDER_NAME,
    },
    options,
)

const expiryMonElement = container.create(
    {
        table: process.env.REACT_APP_SKYFLOW_TABLE,
        column: 'exp_month',
        inputStyles: {
            base: {
                border: '1px solid #eae8ee',
                padding: '10px 16px',
                borderRadius: '4px',
                color: '#1d1d1d',
                height: '50px',
            },
            complete: {
                color: '#4caf50',
            },
            empty: {},
            focus: {},
            invalid: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        labelStyles: {
            base: {
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: 'Arial',
                marginBottom: '10px',
                color: '#7b7b7b',
            },
        },
        errorTextStyles: {
            base: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        placeholder: 'Expiry Month',
        label: 'Expiry Month',
        type: Skyflow.ElementType.EXPIRATION_MONTH,
    },
    options,
)

const expiryYearElement = container.create(
    {
        table: process.env.REACT_APP_SKYFLOW_TABLE,
        column: 'exp_year',
        inputStyles: {
            base: {
                border: '1px solid #eae8ee',
                padding: '10px 16px',
                borderRadius: '4px',
                color: '#1d1d1d',
                height: '50px',
            },
            complete: {
                color: '#4caf50',
            },
            empty: {},
            focus: {},
            invalid: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        labelStyles: {
            base: {
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: 'Arial',
                marginBottom: '10px',
                color: '#7b7b7b',
            },
        },
        errorTextStyles: {
            base: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        placeholder: 'Expiry Year',
        label: 'Expiry Year',
        type: Skyflow.ElementType.EXPIRATION_YEAR,
    },
    options,
)

const cardCvvElement = container.create(
    {
        table: process.env.REACT_APP_SKYFLOW_TABLE,
        column: 'cvv',
        inputStyles: {
            base: {
                border: '1px solid #eae8ee',
                padding: '10px 16px',
                borderRadius: '4px',
                color: '#1d1d1d',
                height: '50px',
            },
            complete: {
                color: '#4caf50',
            },
            empty: {},
            focus: {},
            invalid: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        labelStyles: {
            base: {
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: 'Arial',
                marginBottom: '10px',
                color: '#7b7b7b',
            },
        },
        errorTextStyles: {
            base: {
                color: '#f44336',
                padding: '5px 16px',
                fontFamily: 'Arial',
                fontSize: '12px',
            },
        },
        placeholder: 'Card CVV',
        label: 'Card CVV',
        type: Skyflow.ElementType.CVV,
    },
    options,
)

function CardDetails(props) {
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)

    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const cardNumberRef = useRef(null)
    const cardHolderRef = useRef(null)
    const expiryMonRef = useRef(null)
    const expiryYearRef = useRef(null)
    const cvvRef = useRef(null)

    const { setAlert } = alertContext
    const { user } = authContext
    const {
        addSkyflowCard,
        getSkyflowCards,
        deleteSkyflowCard,
        updateSkyflowCard,
        setDefaultSkyflowCard,
        savePaymentMethodSkyflow,
        responseStatus,
        clearResponse,
    } = useContext(StripeCardContext)

    const {
        updateStripeCustomer,
        getStripeCustomer,
        customer_details,
        responseStatus: responseStatusStripeCustomer,
    } = useContext(StripeCustomerContext)

    const [loading, setLoading] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [cHolderReady, setCHolderReady] = useState(false)
    const [cNumberReady, setCNumberReady] = useState(false)
    const [cExpMonReady, setCExpMonReady] = useState(false)
    const [cExpYearReady, setCExpYearReady] = useState(false)
    const [cvvReady, setCvvReady] = useState(false)
    const [changeStatus, setChangeStatus] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const [reload, setReload] = useState(false)
    const creditCardYearOptions = []
    let date = new Date()
    let currentYear = date.getFullYear()
    let upToYear = parseInt(currentYear) + 25
    for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
        creditCardYearOptions.push({
            value: year,
            show: year,
        })
    }
    const creditCardMonthOptions = []
    for (let month = parseInt(1); month <= parseInt(12); month++) {
        let monthValue = ('0' + month).slice(-2)
        creditCardMonthOptions.push({
            value: monthValue,
            show: monthValue,
        })
    }

    const validationCard = Yup.object({
        card_address_line1: Yup.string().required('Required!'),
        card_address_line2: Yup.string(),
        card_address_city: Yup.string().required('Required!'),
        card_address_state: Yup.string().required('Required!'),
        card_address_country: Yup.string().required('Required!'),
        card_address_zip: Yup.string().when('card_address_country', {
            is: '38',
            then: Yup.string().required('Required!').max(6, 'Invalid postal code'),
            otherwise: Yup.string()
                .required('Required!')
                .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
                .max(6, 'Invalid zip code'),
        }),
        // phone: Yup.string().matches(phonenumber, 'Phone number is not valid'),
    })

    const cardFormik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_AUCTIONPAYID,
            customer_id: user ? user.card_paymentid : '',
            card_address_line1: '',
            card_address_line2: '',
            card_address_city: '',
            card_address_state: '',
            card_address_zip: '',
            card_address_country: '' || global.defaultCountry,
            is_primary: '',
            user_id: '',
            id: '',
            card_token: true,
        },
        validationSchema: validationCard,
        onSubmit: async (values) => {
            try {
                if (
                    !cHolderReady ||
                    !cNumberReady ||
                    !cExpMonReady ||
                    !cExpYearReady ||
                    !cvvReady
                ) {
                    setAlert(
                        'Invalid Card Details. Please make sure you enter correct card details',
                        'error',
                    )
                    return
                }
                setDisabled(true)
                const options = {
                    tokens: true,
                    additionalFields: {
                        records: [
                            {
                                table: process.env.REACT_APP_SKYFLOW_TABLE,
                                fields: {
                                    address_line1: cardFormik.values.card_address_line1,
                                    address_line2: cardFormik.values.card_address_line2,
                                    address_city: cardFormik.values.card_address_city,
                                    address_state: cardFormik.values.card_address_state,
                                    address_country: cardFormik.values.card_address_country,
                                    address_zip: cardFormik.values.card_address_zip,
                                    account_id: cardFormik.values.account_id,
                                    customer_id: cardFormik.values.customer_id,
                                    project: process.env.REACT_APP_SKYFLOW_PROJECT || '',
                                    project_user_id: user.id.toString(),
                                    is_default: false,
                                    is_cvv_available: true,
                                },
                            },
                        ],
                    },
                }
                const res = await container.collect(options)
                await savePaymentMethodSkyflow({ ...res.records[0].fields, project: 'skyflow' })
                addSkyflowCard({ skyflow_id: res.records[0].fields.skyflow_id })
            } catch (e) {
                throw e
            }
        },
    })
    const validationupdateCard = Yup.object({
        exp_month: Yup.string().required('Required!'),
        exp_year: Yup.string().required('Required!'),
    })

    const updateCardformik = useFormik({
        initialValues: {
            account_id: process.env.REACT_APP_AUCTIONPAYID,
            customer_id: user ? user.card_paymentid : '',
            skyflow_id: '',
            exp_month: '',
            exp_year: '',
        },
        validationSchema: validationupdateCard,
        onSubmit: (values) => {
            setDisabled(true)
            const { skyflow_id, exp_month, exp_year } = values
            let expiry_year = exp_year.slice(-2)
            const records = {
                record: {
                    fields: {
                        exp_month,
                        exp_year: expiry_year,
                    },
                },
            }
            updateSkyflowCard({
                skyflow_id,
                records,
            })
        },
    })

    console.log(data, 'pppppppp')

    const formikSelection = useFormik({
        initialValues: {
            data: '',
            status: '',
        },
        onSubmit: async (values) => {
            setDisabled(true)
            const dataValue = values
            if (dataValue.status === 'delete') {
                deleteSkyflowCard({ skyflow_id: dataValue.data.id })
                setChangeStatus(false)
                props.function(false, 'new', null)
            } else if (dataValue.status === 'default') {
                setDefaultSkyflowCard({ skyflow_id: dataValue.data.id })
                setChangeStatus(false)
                props.function(false, 'new', null)
            }
        },
    })

    cardHolderElement.on(Skyflow.EventName.CHANGE, (state) => {
        state.isValid ? setCHolderReady(true) : setCHolderReady(false)
    })

    cardNumberElement.on(Skyflow.EventName.CHANGE, (state) => {
        state.isValid ? setCNumberReady(true) : setCNumberReady(false)
    })

    expiryYearElement.on(Skyflow.EventName.CHANGE, (state) => {
        state.isValid ? setCExpYearReady(true) : setCExpYearReady(false)
    })

    expiryMonElement.on(Skyflow.EventName.CHANGE, (state) => {
        state.isValid ? setCExpMonReady(true) : setCExpMonReady(false)
    })

    cardCvvElement.on(Skyflow.EventName.CHANGE, (state) => {
        state.isValid ? setCvvReady(true) : setCvvReady(false)
    })

    useEffect(() => {
        if (props.data.popup && props.data.status === 'new') {
            cardNumberElement.mount('#' + cardNumberRef.current.id)
            cardHolderElement.mount('#' + cardHolderRef.current.id)
            expiryMonElement.mount('#' + expiryMonRef.current.id)
            expiryYearElement.mount('#' + expiryYearRef.current.id)
            cardCvvElement.mount('#' + cvvRef.current.id)
        }

        return () => {
            cardNumberElement.clearValue()
            cardHolderElement.clearValue()
            expiryMonElement.clearValue()
            expiryYearElement.clearValue()
            cardCvvElement.clearValue()
            cardNumberElement.unmount()
            cardHolderElement.unmount()
            expiryMonElement.unmount()
            expiryYearElement.unmount()
            cardCvvElement.unmount()
        }
    }, [props.data.popup])

    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                formikSelection.values.data = props.data.data
                formikSelection.values.status = props.data.status
                setChangeStatus(true)
            } else if (props.data.status === 'default') {
                formikSelection.values.data = props.data.data
                formikSelection.values.status = props.data.status
                setChangeStatus(true)
            } else if (props.data.status === 'update') {
                updateCardformik.values.skyflow_id = props.data.data.id
                updateCardformik.values.exp_month = props.data.data.exp_month
                updateCardformik.values.exp_year = '20' + props.data.data.exp_year
                updateCardformik.values.customer_id = props.data.data.customer
                setReload(!reload)
            }
            setDisabled(false)
        }
    }, [props.data.data])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'skyflow_add') {
                if (responseStatus.status === 'success') {
                    cardFormik.resetForm()
                    getSkyflowCards()
                    props.function(false, 'new', null)
                }
            } else if (
                responseStatus.from === 'skyflow_delete' ||
                responseStatus.from === 'skyflow_update' ||
                responseStatus.from === 'skyflow_default'
            ) {
                if (responseStatus.status === 'success') {
                    // console.log('UPDATEDDD')
                    formikSelection.resetForm()
                    getSkyflowCards()
                    props.function(false, 'new', null)
                }
            }
            setDisabled(false)
            clearResponse()
        }
    }, [responseStatus])

    useEffect(() => {
        if (responseStatusStripeCustomer) {
            if (responseStatusStripeCustomer.status === 'success') {
                if (responseStatusStripeCustomer.from === 'update') {
                    setDisabled(false)
                }
            }
        }
    }, [responseStatusStripeCustomer])

    const cardUpdate = {
        formik: updateCardformik,
        data: [
            {
                label: 'Expiry Month',
                placeholder: 'MM',
                class: 'col-6',
                type: 'select',
                name: 'exp_month',
                options: creditCardMonthOptions,
            },
            {
                label: 'Expiry Year',
                placeholder: 'YYYY',
                class: 'col-6',
                type: 'select',
                name: 'exp_year',
                options: creditCardYearOptions,
            },
        ],
    }

    const cardDetails = {
        formik: cardFormik,
        data: [
            {
                label: 'Address line 1',
                type: 'text',
                placeholder: 'Enter address line1',
                class: 'col-12',
                name: 'card_address_line1',
                upperLabel: true,
            },
            {
                label: 'Address line 2',
                type: 'text',
                placeholder: 'Enter address line2',
                class: 'col-12',
                name: 'card_address_line2',
                upperLabel: true,
            },
            {
                label: 'City',
                type: 'text',
                placeholder: 'Enter city',
                class: 'col-md-6 col-12',
                name: 'card_address_city',
                upperLabel: true,
            },
            // {
            //     label: 'Card address state',
            //     type: 'text',
            //     placeholder: 'Enter card address state',
            //     class: 'col-12',
            //     name: 'card_address_state',
            // },
            // {
            //     label: 'Card address country',
            //     type: 'text',
            //     placeholder: 'Enter card address country',
            //     class: 'col-12',
            //     name: 'card_address_country',
            // },
            {
                label: 'Country',
                placeholder: 'Select country',
                class: 'col-md-6 col-12',
                type: 'select',
                options: swapCountry(countryList).map((country) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = country.name
                    busCategoryChanged.value = {
                        country_code: country.country_code,
                        location_id: country.location_id,
                    }
                    return busCategoryChanged
                }),
                name: 'card_address_country',
                upperLabel: true,
            },
            {
                label: `${cardFormik.values.card_address_country == 38 ? 'Provinces' : 'State'}`,
                type: 'select',
                placeholder: `Select ${
                    cardFormik.values.card_address_country == 38 ? 'provinces' : 'state'
                }`,
                class: 'col-md-6 col-12',
                options: stateList.map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = buscat.name
                    busCategoryChanged.value = buscat.name
                    return busCategoryChanged
                }),
                name: 'card_address_state',
                upperLabel: true,
            },
            {
                label: `${cardFormik.values.card_address_country == 38 ? 'Postal code' : 'Zip'}`,
                type: 'text',
                placeholder: `Enter ${
                    cardFormik.values.card_address_country == 38 ? 'postal code' : 'zip'
                }`,
                class: 'col-md-6 col-12',
                name: 'card_address_zip',
                upperLabel: true,
            },
        ],
    }
    useEffect(() => {
        if (user) {
            cardFormik.values.customer_id = user.card_paymentid
            updateCardformik.values.customer_id = user.card_paymentid
        }
    }, [user])

    const getLocationData = () => {
        var body = {
            countryCode: cardFormik.values.card_address_country.split(',')[1],
        }
        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    // console.log(response.data.country)
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [cardFormik.values.card_address_country])

    return (
        <>
            <Popup
                open={props.data.popup && props.data.status === 'new'}
                size="md"
                handleClose={() => props.function(false, 'new', null)}
                modaltitle="Add Card Details"
            >
                <div className="cardDetails skFlwFrm">
                    <form onSubmit={cardFormik.handleSubmit} autoComplete="nofill">
                        <div className="row skyFlwCard">
                            <div className="col-md-6 col-12">
                                <div id="cardholder" ref={cardHolderRef}></div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div id="cardnumber" ref={cardNumberRef} />
                            </div>
                            <div className="col-md-6 col-12">
                                <div id="expmon" ref={expiryMonRef}></div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div id="expyear" ref={expiryYearRef}></div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div id="cvv" ref={cvvRef}></div>
                            </div>
                        </div>
                        <div className="row">{mapData(cardDetails)}</div>
                        <div className="row">
                            <div className="col-12">
                                <PrimaryButton
                                    disabled={disabled}
                                    btnSize="large"
                                    type="submit"
                                    label={'Save'}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </Popup>

            <CustomDialog title={'Confirmation'} open={changeStatus} function={changeChangeStatus}>
                <h5>
                    {formikSelection.values.status === 'delete'
                        ? 'Are you sure you want to delete'
                        : formikSelection.values.status === 'default'
                        ? 'Are you sure you want to make this default'
                        : 'Are you sure you want to change the status'}
                </h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" disabled={disabled} />
                    </form>
                </div>
            </CustomDialog>

            <Popup
                open={props.data.popup && props.data.status === 'update'}
                handleClose={() => props.function(false, 'update', null)}
                size="md"
                modaltitle="Update Card Details"
            >
                <form onSubmit={updateCardformik.handleSubmit} autoComplete="nofill">
                    <div className="row">
                        {mapData(cardUpdate)}
                        <div className="col-12">
                            <PrimaryButton
                                disabled={disabled}
                                btnSize="large"
                                type="submit"
                                label={'Save'}
                            />
                        </div>
                    </div>
                </form>
            </Popup>
        </>
    )
}

export default CardDetails
