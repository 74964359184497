import React, { useState, useEffect, useContext } from 'react'
import { Switch, Route, BrowserRouter, useLocation } from 'react-router-dom'
import { Redirect } from 'react-router'
import Favourites from './Favourites'
import Checkout from './Checkout'
import Cart from './cart'
import ProductAuction from './ProductAuction'
import SellerInvoice from './SellerInvoice'
import Invoice from './Invoices/Invoice'
import ChangePassword from './ChangePassword'
import faq from './StaticPages/faq'
import howItWorks from './StaticPages/howItWorks'
import aboutus from './StaticPages/aboutus'
import preference from './Preference'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import Registration from './Registration'
import Home from './Home'
import Auctions from './Auctions'
import AuctionSearch from './AuctionSearch'
import Gallery from './Gallery'
import Profile from './Profile'
import MyBids from './MyBids'
import MyLots from './MyLots'
import Transactions from './Transactions'
import Cards from './cards'
import SellerDashboard from './DashboardSeller'
import PostProduct from './PostProduct'
import PostAuction from './PostAuction/PostAuction'
import Static from './Static'

import authContext from '../../Product/context/auth/authContext'
import termsOfService from './StaticPages/termsOfService'
import ContactUs from './ContactUs'
import SavedSearch from './SavedSearch'
import Reports from './Reports'
import ProductView from './ProductView'
import AddBank from './AddBank'
import SellerDashboardOverview from './SellerDashboardOverview'
import PhotoUploader from './PhotoUploader'
import Standalone from '../../Component/SlidePanel/Standalone'
import PhotoUploadProcedure from './PhotoUploader/photoUploadProcedure'
import Message from './Message'
import PostBuynow from './PostBuynow'
import EditBuyNow from './EditBuyNow'
import BroadCaster from '../../utils/CommonFunctionality/VideoAuction/BroadCaster'
import VideoStream from './VideoStream'
import LiveAuction from './LiveAuction'
import Buynow from './DashboardSeller/Buynow'
import LiveAuctionView from './LiveAuction/LiveAuctionView'
import { commonPaths } from '../../utils/commonFunctions'
import MaintenanceMode from './MaintenanceMode'
import BecomeSeller from './BecomeSeller'
import AboutUs from './Static/AboutUs'
import PrivacyPolicy from './StaticPages/PrivacyPolicy'
import IdleTimerContainer from '../../utils/IdleTimerContainer'
import AuthContext from '../../Product/context/auth/authContext'
import AlertContext from '../../Product/context/alert/alertContext'
import { handleRedirectInternal } from '../../Product/common/components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Referral from './Referral'
import ACH from './ACH'
import Common from './StaticPages/common'
import Billing from './BidderSuspension/Billing'
import Appointments from './Appointments'
import Pickups from './Pickups'
import Abandoned from './Abandoned'
import PaidInvoices from './PaidInvoices'
import UnpaidInvoices from './UnpaidInvoices'
import Returns from './Returns'
import PastAuction from './PastAuction'
const PrivateRoute = ({ component: Component, ...rest }) => (
    <>
        <Route
            {...rest}
            render={(props) =>
                localStorage.token ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    </>
)

export default function LiquidationTwoRoutes() {
    const { pathname } = useLocation()
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const [isRedirecting, setIsRedirecting] = useState(false)
    const { setAlert } = alertContext
    const { user, unapid_inv, isAuthenticated } = authContext
    const history = useHistory()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])
    const isWizardTheme = document.body.classList[1] == 'wizardTheme'
    const checkThemeClassName = document.body.className == 'liquidationTwo primeBids'

    useEffect(() => {
        if (user && !isRedirecting) {
            if (unapid_inv?.length > 0) {
                if (user.status == 'suspended' && pathname != '/checkout/auction') {
                    setIsRedirecting(true)
                    var in_id = []
                    unapid_inv.map((data) => {
                        in_id.push(data.common_invoice)
                    })
                    handleRedirectInternal(history, `checkout/auction?id=${in_id.join('&id=')}`)
                    setAlert(
                        'Your Account Was Suspended.Please Pay All Unpaid Invoice And Activate.',
                        'error',
                    )
                }
            } else {
                if (
                    user.status == 'suspended' &&
                    pathname != '/dashboard/billing' &&
                    pathname != '/dashboard/cards'
                ) {
                    setIsRedirecting(true)
                    handleRedirectInternal(history, `dashboard/billing`)
                    setAlert(
                        'Your Account Was Suspended.Please Pay Re-activate Fee And Activate.',
                        'error',
                    )
                }
            }
        }
    }, [pathname, user, unapid_inv, isRedirecting])

    useEffect(() => {
        if (isWizardTheme) {
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.async = true
            script.setAttribute('embed-id', '7f61fe67-e4f8-46a3-b518-6678b9328b35')
            script.src =
                'https://embed.adabundle.com/embed-scripts/7f61fe67-e4f8-46a3-b518-6678b9328b35'

            document.body.appendChild(script)

            return () => {
                document.body.removeChild(script)
            }
        }
    }, [isWizardTheme])

    useEffect(() => {
        if (user) {
            if (
                (user.zip == '' ||
                    user.first_name == '' ||
                    user.last_name == '' ||
                    user.country == '' ||
                    user.city == '' ||
                    user.address1 == '') &&
                pathname != '/dashboard/profile'
            ) {
                handleRedirectInternal(history, `dashboard/profile`)
                setAlert('Please complete your profile details.', 'error')
            }
        }
    }, [pathname, user])

    return (
        <>
            <Route exact path="/maintenance" component={MaintenanceMode} />
            <Route exact path="/" component={Home} />
            <Route exact path="/faq" component={faq} />
            <Route exact path="/aboutus" component={aboutus} />
            <Route exact path="/wizardbins" component={aboutus} />
            <Route exact path="/termsOfService" component={termsOfService} />
            <Route exact path="/how_it_works" component={howItWorks} />
            <Route exact path="/privacy_policy" component={PrivacyPolicy} />
            {checkThemeClassName ? (
                <PrivateRoute exact path="/search" component={AuctionSearch} />
            ) : (
                <Route exact path="/search" component={AuctionSearch} />
            )}
            <Route exact path="/auctions" component={Auctions} />
            <Route exact path="/past_auctions" component={PastAuction} />

            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/search/autologin/:login_email" component={Login} />
            <Route exact path="/autologin/:login_email" component={Login} />
            <Route exact path="/search/auto-login/:login_email" component={Login} />
            <Route exact path="/auto-login/:login_email" component={Login} />
            <Route exact path="/forgot_password" component={ForgotPassword} />
            <Route exact path="/sign-up" component={Registration} />
            <Route exact path="/login/forgot_password" component={ChangePassword} />
            <Route exact path="/search/product-buyer-auction/:id" component={ProductAuction} />
            <Route exact path="/liveauctionview/:id" component={LiveAuctionView} />
            <Route exact path="/becomeSeller" component={BecomeSeller} />
            <PrivateRoute exact path="/dashboard/profile" component={Profile} />
            <PrivateRoute exact path={commonPaths.mybids} component={MyBids} />
            <PrivateRoute exact path={commonPaths.mylots} component={MyLots} />
            <PrivateRoute exact path="/cart" component={Cart} />
            <Route exact path="/changepasswordusers" component={ChangePassword} />
            <Route exact path="/contact_us" component={ContactUs} />
            {/* <Route exact path="/product/:id" component={ProductView} /> */}
            <Route exact path="/productView/:id" component={ProductView} />
            <PrivateRoute exact path="/dashboard/billing" component={Billing} />
            <PrivateRoute exact path="/checkout/:type" component={Checkout} />
            <PrivateRoute exact path="/invoice/:type" component={Invoice} />
            <PrivateRoute exact path="/dashboard/transactions" component={Transactions} />
            <PrivateRoute exact path={commonPaths.returns} component={Returns} />
            <PrivateRoute exact path={commonPaths.appointments} component={Appointments} />
            <PrivateRoute exact path={commonPaths.pickups} component={Pickups} />
            <PrivateRoute exact path={commonPaths.abandoned} component={Abandoned} />
            <PrivateRoute exact path={commonPaths.paid_invoices} component={PaidInvoices} />
            <PrivateRoute exact path={commonPaths.unpaid_invoices} component={UnpaidInvoices} />
            <PrivateRoute exact path="/dashboard/cards" component={Cards} />
            <PrivateRoute exact path="/dashboard/favorites" component={Favourites} />
            <PrivateRoute exact path="/dashboard/savedSearch" component={SavedSearch} />

            <PrivateRoute exact path="/sellerdashboard" component={SellerDashboard} />
            <PrivateRoute exact path="/post-project" component={PostProduct} />
            <PrivateRoute exact path="/edit-project" component={PostProduct} />
            <PrivateRoute exact path="/post-auction" component={PostAuction} />
            <PrivateRoute exact path="/post-buynow" component={PostBuynow} />
            <PrivateRoute exact path="/edit-buynow/:id" component={EditBuyNow} />
            <PrivateRoute exact path="/edit-auction/:id" component={PostAuction} />

            <PrivateRoute exact path="/onboard" component={AddBank} />

            <Route exact path="/trust" component={Static} />
            <Route exact path="/payment" component={Static} />
            <Route exact path="/services" component={Static} />
            <Route exact path="/our_process" component={Static} />
            <Route exact path="/considerations" component={Static} />
            <Route exact path="/about_us" component={AboutUs} />
            <Route exact path="/careers" component={Common} />
            <Route exact path="/sell_with_us" component={Common} />
            <Route exact path="/help" component={Common} />
            <Route exact path="/seller_info" component={Common} />

            <PrivateRoute exact path="/dashboard/preference" component={preference} />
            <PrivateRoute exact path="/dashboard/invoices" component={SellerInvoice} />
            <PrivateRoute exact path="/dashboard/reports" component={Reports} />
            <PrivateRoute exact path="/dashboard/overview" component={SellerDashboardOverview} />
            <PrivateRoute exact path="/dashboard/photouploader" component={PhotoUploader} />
            <PrivateRoute exact path="/dashboard/buynow" component={Buynow} />
            <PrivateRoute exact path="/dashboard/ach" component={ACH} />
            <PrivateRoute
                exact
                path="/dashboard/requirements-for-uploading-photo"
                component={PhotoUploadProcedure}
            />
            <PrivateRoute exact path="/dashboard/message" component={Message} />
            <PrivateRoute exact path="/video-auction/broadcast" component={VideoStream} />
            <PrivateRoute exact path="/liveLots" component={LiveAuction} />
            <Route exact path="/auctions/:type" component={Auctions} />
            <PrivateRoute exact path="/referral" component={Referral} />
            {global?.storeConfigration?.session_timeout ? <IdleTimerContainer /> : ''}
        </>
    )
}
