import React, { useEffect, useState, useContext } from 'react'
import Layout from '../Layout'
import SearchAuctionList from '../../../Component/Search/SearchAuctionList'
import ProductContext from '../../../Product/context/product/productContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import '../Auctions/Auctions.css'
import { Button } from '@material-ui/core'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import Loader from '../../../assets/loader'
import Loaders from '../../../Product/components/molecules/Loaders'
import SEO from '../../../utils/CommonFunctionality/SEO'
import GridProduct from '../../../Component/ProductCard/gridProduct'

if (sessionStorage.getItem('sh_limit') === null) sessionStorage.setItem('sh_limit', '50')

const PastAuction = (props) => {
    const productContext = useContext(ProductContext)
    const {
        search_allauctions,
        getAllSearchAuctions,
        search_allauctionproducts,
        getLotList,
        productLocalState,
    } = productContext
    const [loading, setLoading] = useState(true)
    const [viewAuction, setViewAuction] = useState([])
    const [auctionView, setAuctionView] = useState('Grid')
    const [subLotData, setSubLotData] = useState([])
    const [auction_type, setAuction_type] = useState('closed')
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: sessionStorage.getItem('sh_limit'),
        orderby: '9',
        is_auction: '',
        searchbar: '',
        auc_type: '3',
        type: '',
        auction_state:
            productLocalState.globalLocationFilter == 1
                ? []
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[1]?.trim()]
                : [productLocalState.globalLocation],
        auction_city:
            productLocalState.globalLocationFilter == 1
                ? [productLocalState.globalLocation]
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[0]?.trim()]
                : [],
    })
    const handleClose = () => setSelectedProduct(null)
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        orderby: '2',
        lotof: '',
        limit: 50,
        market_status: 'open',
        auction_io: 1,
    })

    useEffect(() => {
        setLoading(true)
        var data = auctionSearch

        {
            var auction_type_loc = props.match.params.type
            setAuction_type(auction_type_loc)
            data.auc_type = '3'
            data.type = 'closed'
        }
        var send_data = auctionSearch
        ;(send_data.auction_city =
            productLocalState.globalLocationFilter == 1
                ? [productLocalState.globalLocation]
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[0]?.trim()]
                : []),
            (send_data.auction_state =
                productLocalState.globalLocationFilter == 1
                    ? []
                    : productLocalState.globalLocationFilter == 2
                    ? [productLocalState.globalLocation?.split(',')?.[1]?.trim()]
                    : [productLocalState.globalLocation]),
            getAllSearchAuctions(auctionSearch, 'activeHome')
    }, [auctionSearch, productLocalState.globalLocation])
    useEffect(() => {
        if (viewAuction.length) {
            setSubLotSearch({ ...subLotSearch, lotof: viewAuction[0].id })
        }
    }, [viewAuction])

    useEffect(() => {
        if (subLotSearch.lotof) {
            getLotList(subLotSearch, 'homePageAuction')
        }
    }, [subLotSearch])

    useEffect(() => {
        setLoading(false)
        if (search_allauctions.from === 'activeHome') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    const onHandlePage = (event, value) => {
        setAuctionSearch({ ...auctionSearch, page: value })
    }
    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    useEffect(() => {
        if (search_allauctionproducts.from === 'homePageAuction') {
            // console.log('search_allauctionproducts', search_allauctionproducts)
            setSubLotData(search_allauctionproducts.results)
        }
    }, [search_allauctionproducts])

    const pageOptions = [
        {
            value: '25',
            show: 'Results 25 per page',
        },
        {
            value: '50',
            show: 'Results 50 per page',
        },
        {
            value: '100',
            show: 'Results 100 per page',
        },
        {
            value: '150',
            show: 'Results 150 per page',
        },
    ]

    return (
        <Layout props={props}>
            <SEO title={`${global?.storeDetails?.name} | PastAuctions`} />

            <div className="customContainer auctionsSearch">
                <div className="d-flex justify-content-between align-items-center flex-wrap mt-5 mb-3 auctionSearchCnt">
                    <h2 className="auctionListTitle" style={{ paddingLeft: '40%' }}>
                        Past Auctions
                    </h2>
                    <div className="auctionsMiscFilter">
                        <CustomSelect
                            label="Results per page"
                            name="resultsPerPage"
                            selectType="noBorder"
                            value={auctionSearch.sh_limit}
                            size="small"
                            onChange={(event, editor) => {
                                setAuctionSearch({ ...auctionSearch, sh_limit: event.target.value })
                                sessionStorage.setItem('sh_limit', event.target.value)
                            }}
                        >
                            {pageOptions.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>
                    </div>
                </div>
                <>
                    <>
                        {loading ? (
                            <div className="text-center">
                                <Loaders name="product_grid_view" isLoading={loading} loop={1} />
                            </div>
                        ) : viewAuction.length === 0 ? (
                            <NoRecordsFound />
                        ) : auctionSearch.auc_type == 3 ? (
                            <div className={`searchResults ${auctionView}`}>
                                {viewAuction.map((data, index) => (
                                    <MultisellerListCard
                                        data={data}
                                        key={index}
                                        details={`search/product-buyer-auction/${data.id}`}
                                        card_theme="liquidationTwo"
                                        handleLotClick={handleClick}
                                        PastAuction="PastAuction"
                                    />
                                ))}
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                </>
            </div>
        </Layout>
    )
}

export default PastAuction
