import React, { useState, useContext, useEffect } from 'react'
import PaginationComponent from '../../../Component/Pagination'
import { Link, useHistory } from 'react-router-dom'
import Bidhistory from '../../../Component/Bidhistory'
import { getFilePath } from '../../../utils/commonFunctions'
import './product.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Timer from '../../../Product/common/timer'
import Bidding from '../../../Product/components/molecules/Bidding/BiddingItem'
import AuthContext from '../../../Product/context/auth/authContext'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import { Button } from '@material-ui/core'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import TableView from '../../../Component/ProductCard/TableView'
import { useMediaQuery } from 'react-responsive'

const SubLotsComponent = (props) => {
    const authContext = useContext(AuthContext)
    const [auctionView, setAuctionView] = useState(props.view_type)

    const [selectedHistory, setSelectedHistory] = useState({})

    const { user, isAuthenticated } = authContext

    const openHistory = (property) => {
        setSelectedHistory(property)
    }

    useEffect(() => {
        setAuctionView(props.view_type)
    }, [props.view_type])

    const pageOptions = [
        {
            value: '30',
            show: 'Results 30 per page',
        },
        {
            value: '50',
            show: 'Results 50 per page',
        },
        {
            value: '100',
            show: 'Results 100 per page',
        },
        {
            value: '150',
            show: 'Results 150 per page',
        },
    ]
    const sortBy = [
        { value: '7', show: 'Ending Soon' },
        // { value: '2', show: 'Lot Id' },
        { value: '16', show: 'Best Deals' },
        { value: '8', show: 'Price lowest - Price highest' },
        { value: '9', show: 'Price highest - Price lowest' },
        // { value: '6', show: 'Lowest Bids' },
    ]

    const categoryOptions = [{ value: '', show: 'All' }].concat(
        props.parentcategories.map((data) => ({
            value: `${data.id}`,
            show: `${data.name} (${data.cat_count})`,
        })),
    )
    const conditionOptions = [{ value: '', show: 'All' }].concat(
        global.condition_data.map((item) => {
            return {
                value: `${item.condition}`,
                show: `${item.condition}`,
            }
        }),
    )
    const mobileMedia = useMediaQuery({ query: '(max-width: 500px)' })

    const isBidDepotProject = document.querySelector('body').classList.contains('BidAuctionDepot')
    useEffect(() => {
        if ((mobileMedia && isBidDepotProject) || auctionView == 'List') {
            props.sendType('List')
        } else {
            props.sendType('Grid')
        }
    }, [])
    return (
        <div
            className={
                isBidDepotProject && mobileMedia ? 'msSublots searchRt mb-4' : 'msSublots mb-4'
            }
            id="sublot"
        >
            <div className="msSublotsMisc d-flex justify-content-end align-items-center flex-wrap mt-5 mb-3">
                {/* <PaginationComponent
                    onPageChange={(pageNumber) => {
                        document.getElementById('sublot').scrollIntoView()
                        props.setSubLotSearch({ ...props.subLotSearch, page: pageNumber })
                    }}
                    totalProductCount={props.subLotDataCount}
                    currentPage={props.subLotSearch.page}
                    resultToShow={props.subLotSearch.limit}
                /> */}
                <div className="gridListToggle">
                    <Button
                        className={auctionView === 'Grid' ? 'active' : ''}
                        onClick={() => {
                            //setAuctionView('Grid')
                            props.sendType('Grid')
                        }}
                    >
                        <span className="material-icons">apps</span>Grid
                    </Button>
                    <Button
                        className={auctionView === 'List' ? 'active' : ''}
                        onClick={() => {
                            //setAuctionView('List')
                            props.sendType('List')
                        }}
                    >
                        <span className="material-icons">view_list</span>List
                    </Button>
                </div>
                <div className="auctionsMiscFilter">
                    <CustomSelect
                        label="Condition"
                        name="rating_multiple_io"
                        selectType="noBorder"
                        value={props.subLotSearch.rating_multiple_io}
                        size="small"
                        onChange={(event, editor) => {
                            props.setSubLotSearch({
                                ...props.subLotSearch,
                                rating_multiple_io: event.target.value,
                                page: 1,
                            })
                        }}
                    >
                        {conditionOptions.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        label="Category"
                        name="cat_id_multiple_io"
                        selectType="noBorder"
                        value={props.subLotSearch.cat_id_multiple_io}
                        size="small"
                        onChange={(event, editor) => {
                            props.setSubLotSearch({
                                ...props.subLotSearch,
                                cat_id_multiple_io: event.target.value,
                                page: 1,
                            })
                        }}
                    >
                        {categoryOptions.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        label="Results per page"
                        name="resultsPerPage"
                        selectType="noBorder"
                        value={props.subLotSearch.limit}
                        size="small"
                        onChange={(event, editor) => {
                            props.setSubLotSearch({
                                ...props.subLotSearch,
                                limit: event.target.value,
                                page: 1,
                            })
                        }}
                    >
                        {pageOptions.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        label="Sort By"
                        name="sortBy"
                        selectType="noBorder"
                        value={props.subLotSearch.sortBy}
                        size="small"
                        onChange={(event, editor) => {
                            props.setSubLotSearch({
                                ...props.subLotSearch,
                                sortBy: event.target.value,
                            })
                        }}
                    >
                        {sortBy.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                </div>
            </div>

            <div className={`lotResults ${auctionView} liquidationBuyCard`}>
                {auctionView === 'Grid' ? (
                    props.lotData.map((data, index) => (
                        <GridProduct
                            viewProduct={props.lotData}
                            // setViewProduct={props.setSubLotSearch}
                            data={data}
                            key={index}
                            lotImages={data.lotImages}
                            selectedProduct={props.selectedPostion}
                            link={`/gallery/product-view/${data.id}`}
                            handleClick={props.handleClick}
                            timerTheme=""
                            timerIcon={true}
                            className={data.auction ? 'auction' : 'buynow'}
                            sliderTheme="multiseller"
                            bidStatus={true}
                            cardTheme="bidAuction"
                            bidBtnLabel={
                                <>
                                    <span className="material-icons-outlined">gavel</span>
                                    Place Bid
                                </>
                            }
                            type={data.auction ? 'auction' : 'buynow'}
                        />
                    ))
                ) : (
                    <>
                        {props.lotData.map((data, index) => (
                            <MultisellerListCard
                                cardTheme="bidAuction"
                                lotData={data}
                                viewProduct={props.lotData}
                                // setViewProduct={props.setSubLotSearch}
                                className={data.auction ? 'auction' : 'buynow'}
                                key={index}
                                bidStatus={true}
                                selectedProduct={props.selectedPostion}
                                allsearchitems={props.lotData}
                                type="lot"
                                handleClick={props.handleClick}
                                details={`/gallery/product-view/${data.id}`}
                                sliderTheme="multiseller"
                                card_theme="liquidationTwo"
                                standalonePage={true}
                                bidBtnLabel={
                                    <>
                                        <span className="material-icons-outlined">gavel</span>
                                        Bid
                                    </>
                                }
                            />
                        ))}
                    </>
                    // <div
                    //     className="table-responsive lineItemBidTable mt-3 sublots-table"
                    //     id="sublot"
                    // >

                    // </div>
                    // <div
                    //     className="table-responsive lineItemBidTable mt-3 sublots-table"
                    //     id="sublot"
                    // >
                    //     <TableView
                    //         lotData={props.lotData}
                    //         selectedPostion={props.selectedPostion}
                    //         viewProduct={props.lotData}
                    //         setViewProduct={props.setSubLotSearch}
                    //         isBidAuction={true}
                    //         cardTheme="bidAuction"
                    //     />
                    // </div>
                )}
            </div>

            <PaginationComponent
                onPageChange={(pageNumber) => {
                    document.getElementById('sublot').scrollIntoView()
                    props.setSubLotSearch({ ...props.subLotSearch, page: pageNumber })
                }}
                totalProductCount={props.subLotDataCount}
                currentPage={props.subLotSearch.page}
                resultToShow={props.subLotSearch.limit}
            />
            <Bidhistory
                modaltitle="Bid history"
                open={Object.keys(selectedHistory).length !== 0}
                value={selectedHistory}
                serverTime={props.serverTimeSocket}
                handleClose={() => openHistory({})}
            />
        </div>
    )
}

export default SubLotsComponent
