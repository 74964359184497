import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import DashboardPanel from './DashboardPanel'
import { NavLink, useHistory } from 'react-router-dom'
import SEO from '../../../utils/CommonFunctionality/SEO'

function DashboardLayout(props) {
    const history = useHistory()
    const isWizardTheme = document.body.className == 'liquidationTwo wizardTheme'

    return (
        <div className="multisellerDashboardLayout customContainer">
            <SEO
                title={`${global?.storeDetails?.name} | ${
                    props.seller ? 'Seller' : 'Buyer'
                } Dashboard`}
            />

            <DashboardPanel title={props.title} subtitle={props.subtitle} seller={props.seller} />

            <div className="dashboardRt">
                {!isWizardTheme && (
                    <div className="maTitle d-flex justify-content-between align-items-center w-100">
                        <h2 className="dashTitle">{props.title}</h2>
                        <Button
                            onClick={() => {
                                history.goBack()
                                window.scrollTo(0, 0)
                            }}
                            className="moveBack"
                        >
                            <span className="material-icons">arrow_back</span>
                            Back
                        </Button>
                    </div>
                )}
                {props.children}
            </div>
        </div>
    )
}

export default DashboardLayout
