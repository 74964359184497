import React, { useState, useEffect, useContext, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import AllProducts from '../../../Component/MyBids/AllProducts'
import { useLocation, useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../../Product/common/components'
import { commonPaths } from '../../commonFunctions'
import StripeCustomerContext from '../../../Product/context/stripe/customer/customerContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import UserActivity from '../UserActivity'
import buyerContext from '../../../Product/context/buyer/buyerContext'
import axios from 'axios'
import { socket } from '../../../Product/common/socket'
import OrderPickup from '../../../Component/MyBids/OrderPickup'
const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            className="customTabsInner dashboard-tabs"
            // component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const MyBidsPanel = (props) => {
    const history = useHistory()
    const search = useLocation().search
    const params = new URLSearchParams(search)
    const [value, setValue] = useState(
        global.storeConfigration?.disabled_auc_mulsel2?.value == 1
            ? 3
            : params.get('s')
            ? parseInt(params.get('s'))
            : 0,
    )
    const [valueWon, setValueWon] = useState(params.get('w') ? parseInt(params.get('w')) : 0)
    const [valueBuy, setValueBuy] = useState(params.get('b') ? parseInt(params.get('b')) : 0)
    const { t } = useTranslation()
    const { clearBuyerState, buyer_allproducts, updateVideoCount } = useContext(buyerContext)

    const authContext = useContext(AuthContext)

    const { user, loadUser, isAuthenticated } = authContext

    const isWizardTheme = document.body.classList[1] == 'wizardTheme'

    const {
        createStripeCustomer,
        addCustomerProfile,
        responseStatus: responseStatusStripeCustomer,
    } = useContext(StripeCustomerContext)
    const buyerAllProductsRef = useRef(buyer_allproducts)
    useEffect(() => {
        buyerAllProductsRef.current = buyer_allproducts
    })
    const handleChange = (event, newValue) => {
        clearBuyerState()
        handleRedirectInternal(history, `${commonPaths.mybids.substring(1)}?s=${newValue}`)
        // setValue(newValue)
        console.log(newValue)
    }
    const handleChangeWon = (event, newValue) => {
        clearBuyerState()
        handleRedirectInternal(history, `${commonPaths.mybids.substring(1)}?s=1&w=${newValue}`)
    }
    const handleChangeBuy = (event, newValue) => {
        clearBuyerState()
        handleRedirectInternal(history, `${commonPaths.mybids.substring(1)}?s=3&b=${newValue}`)
    }
    /*eslint-disable*/
    useEffect(() => {
        if (global.storeConfigration?.disabled_auc_mulsel2?.value == 1) {
            setValue(0)
        } else {
            setValue(params.get('s') ? parseInt(params.get('s')) : 0)
        }
        if (params.get('w')) {
            setValueWon(params.get('w') ? parseInt(params.get('w')) : 0)
        }
        if (params.get('b')) {
            setValueBuy(params.get('b') ? parseInt(params.get('b')) : 0)
        }
    }, [window.location.search])
    console.log(value, 'value+++++++++++++')
    let count = 0
    // useEffect(() => {
    //     if (user) {
    //         socket.on('insertMessages_count', (data) => {
    //             console.log(count++, '+++')
    //             if (buyerAllProductsRef.current?.results?.length) {
    //                 buyerAllProductsRef.current?.results.map((val) => {
    //                     if (user?.id == data.to_id && data.project_id == val.common_invoice) {
    //                         console.log(val, 'val')
    //                         val.msg_count += 1
    //                     }
    //                 })
    //                 updateVideoCount(buyerAllProductsRef.current)
    //             }
    //         })
    //     }

    //     return () => {
    //         console.log('000000000000')
    //         socket.off('insertMessages_count', (data) => {
    //             return data
    //         })
    //     }
    // }, [user])
    //console.log('store', global.storeConfigration?.disable_buynow?.value)
    const isBidAuctionTheme = document.body.classList[0] === 'BidAuction'
    const cancelToken = axios.CancelToken.source()
    const [cancelRequest, setCancelRequest] = useState(undefined)
    useEffect(() => {
        if (
            user &&
            !user.card_paymentid &&
            global.storeConfigration?.credit_card_no_need?.value == 1 &&
            global.storeConfigration?.stripe_integration?.value !== '1' &&
            global.storeConfigration?.enable_cardknox !== '1'
        ) {
            createStripeCustomer({
                account_id: process.env.REACT_APP_AUCTIONPAYID,
                description: 'TEST CUSTOMER',
                email: user.email,
                name: user.first_name + ' ' + user.last_name,
            })
        }
    }, [user])

    useEffect(() => {
        if (
            responseStatusStripeCustomer &&
            global.storeConfigration?.credit_card_no_need?.value == 1 &&
            global.storeConfigration?.enable_cardknox !== '1'
        ) {
            if (responseStatusStripeCustomer.status === 'success') {
                if (responseStatusStripeCustomer.from === 'create') {
                    //console.log('responseStatusStripeCustomer', responseStatusStripeCustomer)
                    addCustomerProfile({
                        customer_id: responseStatusStripeCustomer.data.id,
                    })
                }
                loadUser()
                // setIsLoading(false)
            }
        }
    }, [responseStatusStripeCustomer])

    return (
        <div>
            {global.storeConfigration?.disabled_auc_mulsel2?.value != 1 ? (
                <>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="buyer-dashboard"
                        className="mt-4 liquidationDashTabs"
                    >
                        <Tab
                            label={t('bids_active')}
                            {...a11yProps(0)}
                            disabled={
                                global.storeDetails?.theme === 20 || isWizardTheme
                                    ? false
                                    : value === 0
                            }
                        />
                        {props.storeTheme === 'Lotsofauctions' ? null : (
                            <Tab label={t('bids_won')} {...a11yProps(1)} />
                        )}
                        <Tab
                            label={
                                props.storeTheme === 'Lotsofauctions'
                                    ? 'Closed Items'
                                    : t('bids_lost')
                            }
                            {...a11yProps(props.storeTheme === 'Lotsofauctions' ? 1 : 2)}
                            disabled={
                                global.storeDetails?.theme === 20
                                    ? false
                                    : value === (props.storeTheme === 'Lotsofauctions' ? 1 : 2)
                            }
                        />

                        {global.storeConfigration?.disable_buynow?.value !== '1' &&
                        global.storeConfigration?.need_post_buynow?.value != 0 ? (
                            <Tab
                                label={t('buy_now')}
                                {...a11yProps(props.storeTheme === 'Lotsofauctions' ? 2 : 3)}
                            />
                        ) : null}
                        {global.pluginConfiguration?.make_an_offer?.enable ? (
                            <Tab
                                label={'Make An Offer'}
                                {...a11yProps(props.storeTheme === 'Lotsofauctions' ? 3 : 4)}
                            />
                        ) : null}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <UserActivity page="dashboard_bid_active" />
                        <AllProducts
                            page="bidactive"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            enableRetractBid={props.enableRetractBid}
                            storeTheme={props.storeTheme}
                            noView={props.noView}
                            noProxy={props.noProxy}
                            redirect_product_view={props.redirect_product_view}
                            cancelRequest={cancelRequest}
                            cancelToken={cancelToken}
                            setCancelRequest={setCancelRequest}
                            gridView={props.gridView}
                            auction_city={props.auction_city}
                            searchbar={props.searchbar}
                            searchbarInputNew={props.searchbarInputNew}
                            sortby={props.sortby}
                            hideReturn={props?.hideReturn}
                            enableBidding={props?.enableBidding}
                        />
                    </TabPanel>
                    {props.storeTheme === 'Lotsofauctions' ? null : (
                        <TabPanel value={value} index={1}>
                            <UserActivity page="dashboard_bid_won" />
                            {global?.storeConfigration?.new_schedule_pickup?.value == 1 &&
                            !props.noSchedule ? (
                                <Fragment>
                                    <Tabs
                                        value={valueWon}
                                        onChange={handleChangeWon}
                                        aria-label="buyer-dashboard"
                                        className="mt-4 liquidationDashTabs bidWon"
                                    >
                                        <Tab label={'All'} {...a11yProps(0)} />
                                        <Tab
                                            label={
                                                global.storeDetails?.theme === 20
                                                    ? 'Needs Pick Up Scheduled'
                                                    : 'Schedule Pickup'
                                            }
                                            {...a11yProps(1)}
                                        />
                                        <Tab
                                            label={
                                                global.storeDetails?.theme === 20
                                                    ? 'Pick Up Scheduled'
                                                    : 'Scheduled Pickup'
                                            }
                                            {...a11yProps(2)}
                                        />
                                        {!isBidAuctionTheme ? (
                                            <>
                                                {' '}
                                                {global?.pluginConfiguration?.abandoned_order
                                                    ?.enable == 1 ? (
                                                    <Tab
                                                        label={'Abandoned Orders'}
                                                        {...a11yProps(3)}
                                                    />
                                                ) : null}
                                            </>
                                        ) : (
                                            <Tab label={'Completed'} {...a11yProps(3)} />
                                        )}
                                    </Tabs>
                                    <TabPanel value={valueWon} index={0}>
                                        <AllProducts
                                            page="bidwon"
                                            currency={props.currency}
                                            sliderTheme={props.sliderTheme}
                                            shippingauction={props.shippingauction}
                                            storeTheme={props.storeTheme}
                                            checkout_but_pos={props.checkout_but_pos}
                                            redirect_product_view={props.redirect_product_view}
                                            pickupCode={props.pickupCode}
                                            searchbar={true}
                                            cancelRequest={cancelRequest}
                                            cancelToken={cancelToken}
                                            setCancelRequest={setCancelRequest}
                                            hideReturn={props?.hideReturn}
                                        />
                                    </TabPanel>
                                    <TabPanel value={valueWon} index={1}>
                                        <AllProducts
                                            page="bidwon"
                                            currency={props.currency}
                                            sliderTheme={props.sliderTheme}
                                            shippingauction={props.shippingauction}
                                            storeTheme={props.storeTheme}
                                            checkout_but_pos={props.checkout_but_pos}
                                            redirect_product_view={props.redirect_product_view}
                                            pickupCode={props.pickupCode}
                                            schedule={true}
                                            searchbar={true}
                                            cancelRequest={cancelRequest}
                                            cancelToken={cancelToken}
                                            setCancelRequest={setCancelRequest}
                                            sch_type={1}
                                            hideReturn={props?.hideReturn}
                                        />
                                    </TabPanel>
                                    <TabPanel value={valueWon} index={2}>
                                        <AllProducts
                                            page="bidwon"
                                            currency={props.currency}
                                            sliderTheme={props.sliderTheme}
                                            shippingauction={props.shippingauction}
                                            storeTheme={props.storeTheme}
                                            checkout_but_pos={props.checkout_but_pos}
                                            schedule={true}
                                            redirect_product_view={props.redirect_product_view}
                                            pickupCode={props.pickupCode}
                                            searchbar={true}
                                            cancelRequest={cancelRequest}
                                            cancelToken={cancelToken}
                                            setCancelRequest={setCancelRequest}
                                            sch_type={2}
                                            hideReturn={props?.hideReturn}
                                        />
                                    </TabPanel>
                                    {!isBidAuctionTheme ? (
                                        <>
                                            {global?.pluginConfiguration?.abandoned_order?.enable ==
                                            1 ? (
                                                <TabPanel value={valueWon} index={3}>
                                                    <AllProducts
                                                        page="bidwon"
                                                        currency={props.currency}
                                                        sliderTheme={props.sliderTheme}
                                                        storeTheme={props.storeTheme}
                                                        redirect_product_view={
                                                            props.redirect_product_view
                                                        }
                                                        abandoned={true}
                                                        hideReturn={props?.hideReturn}
                                                    />
                                                </TabPanel>
                                            ) : null}
                                        </>
                                    ) : (
                                        <TabPanel value={valueWon} index={3}>
                                            <AllProducts
                                                page="bidwon"
                                                currency={props.currency}
                                                sliderTheme={props.sliderTheme}
                                                shippingauction={props.shippingauction}
                                                storeTheme={props.storeTheme}
                                                checkout_but_pos={props.checkout_but_pos}
                                                schedule={true}
                                                completed={true}
                                                redirect_product_view={props.redirect_product_view}
                                                pickupCode={props.pickupCode}
                                                searchbar={true}
                                                cancelRequest={cancelRequest}
                                                cancelToken={cancelToken}
                                                setCancelRequest={setCancelRequest}
                                                sch_type={2}
                                                hideReturn={props?.hideReturn}
                                            />
                                        </TabPanel>
                                    )}
                                </Fragment>
                            ) : global?.storeConfigration?.new_schedule_pickup_1?.value == 1 &&
                              !props.noSchedule ? (
                                <Fragment>
                                    <Tabs
                                        value={valueWon}
                                        onChange={handleChangeWon}
                                        aria-label="buyer-dashboard"
                                        className="mt-4 liquidationDashTabs"
                                    >
                                        <Tab label={'All'} {...a11yProps(0)} />
                                        <Tab label={'Order Pick Status'} {...a11yProps(1)} />
                                        {global?.pluginConfiguration?.abandoned_order?.enable ==
                                        1 ? (
                                            <Tab label={'Abandoned Orders'} {...a11yProps(3)} />
                                        ) : null}
                                    </Tabs>
                                    <TabPanel value={valueWon} index={0}>
                                        <AllProducts
                                            page="bidwon"
                                            currency={props.currency}
                                            sliderTheme={props.sliderTheme}
                                            shippingauction={props.shippingauction}
                                            storeTheme={props.storeTheme}
                                            checkout_but_pos={props.checkout_but_pos}
                                            redirect_product_view={props.redirect_product_view}
                                            pickupCode={props.pickupCode}
                                            searchbar={true}
                                            cancelRequest={cancelRequest}
                                            cancelToken={cancelToken}
                                            setCancelRequest={setCancelRequest}
                                            hideReturn={props?.hideReturn}
                                        />
                                    </TabPanel>
                                    <TabPanel value={valueWon} index={1}>
                                        <OrderPickup />
                                    </TabPanel>
                                    {global?.pluginConfiguration?.abandoned_order?.enable == 1 ? (
                                        <TabPanel value={valueWon} index={2}>
                                            <AllProducts
                                                page="bidwon"
                                                currency={props.currency}
                                                sliderTheme={props.sliderTheme}
                                                storeTheme={props.storeTheme}
                                                redirect_product_view={props.redirect_product_view}
                                                abandoned={true}
                                                hideReturn={props?.hideReturn}
                                            />
                                        </TabPanel>
                                    ) : null}
                                </Fragment>
                            ) : (
                                <AllProducts
                                    page="bidwon"
                                    currency={props.currency}
                                    sliderTheme={props.sliderTheme}
                                    shippingauction={props.shippingauction}
                                    storeTheme={props.storeTheme}
                                    checkout_but_pos={props.checkout_but_pos}
                                    redirect_product_view={props.redirect_product_view}
                                    pickupCode={props.pickupCode}
                                    searchbar={true}
                                    searchbarInputNew={props.searchbarInputNew}
                                    sortby={props.sortby}
                                    cancelRequest={cancelRequest}
                                    cancelToken={cancelToken}
                                    setCancelRequest={setCancelRequest}
                                    hideReturn={props?.hideReturn}
                                />
                            )}
                        </TabPanel>
                    )}
                    <TabPanel value={value} index={props.storeTheme === 'Lotsofauctions' ? 1 : 2}>
                        <UserActivity page="dashboard_bid_lost" />
                        <AllProducts
                            page="bidlost"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            storeTheme={props.storeTheme}
                            redirect_product_view={props.redirect_product_view}
                            cancelRequest={cancelRequest}
                            cancelToken={cancelToken}
                            setCancelRequest={setCancelRequest}
                            gridView={props.gridView}
                            auction_city={props.auction_city}
                            searchbar={props.searchbar}
                            searchbarInputNew={props.searchbarInputNew}
                            sortby={props.sortby}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={props.storeTheme === 'Lotsofauctions' ? 2 : 3}>
                        <UserActivity page="dashboard_buynow" />
                        {global?.storeConfigration?.new_schedule_pickup?.value == 1 ? (
                            <Fragment>
                                <Tabs
                                    value={valueBuy}
                                    onChange={handleChangeBuy}
                                    aria-label="buyer-dashboard"
                                    className="mt-4 liquidationDashTabs buyNow"
                                >
                                    <Tab label={'All'} {...a11yProps(0)} />
                                    <Tab
                                        label={
                                            global.storeDetails?.theme === 20
                                                ? 'Needs Pick Up Scheduled'
                                                : 'Schedule Pickup'
                                        }
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        label={
                                            global.storeDetails?.theme === 20
                                                ? 'Pick Up Scheduled'
                                                : 'Scheduled Pickup'
                                        }
                                        {...a11yProps(2)}
                                    />
                                </Tabs>
                                <TabPanel value={valueBuy} index={0}>
                                    <AllProducts
                                        page="buynow"
                                        currency={props.currency}
                                        sliderTheme={props.sliderTheme}
                                        noTimer={props.noTimer}
                                        shippingauction={props.shippingauction}
                                        storeTheme={props.storeTheme}
                                        redirect_product_view={props.redirect_product_view}
                                        pickupCode={props.pickupCode}
                                        cancelRequest={cancelRequest}
                                        cancelToken={cancelToken}
                                        setCancelRequest={setCancelRequest}
                                    />
                                </TabPanel>
                                <TabPanel value={valueBuy} index={1}>
                                    <AllProducts
                                        page="buynow"
                                        currency={props.currency}
                                        sliderTheme={props.sliderTheme}
                                        noTimer={props.noTimer}
                                        shippingauction={props.shippingauction}
                                        storeTheme={props.storeTheme}
                                        redirect_product_view={props.redirect_product_view}
                                        pickupCode={props.pickupCode}
                                        cancelRequest={cancelRequest}
                                        cancelToken={cancelToken}
                                        setCancelRequest={setCancelRequest}
                                        sch_type={1}
                                    />
                                </TabPanel>
                                <TabPanel value={valueBuy} index={2}>
                                    <AllProducts
                                        page="buynow"
                                        currency={props.currency}
                                        sliderTheme={props.sliderTheme}
                                        noTimer={props.noTimer}
                                        shippingauction={props.shippingauction}
                                        storeTheme={props.storeTheme}
                                        redirect_product_view={props.redirect_product_view}
                                        pickupCode={props.pickupCode}
                                        cancelRequest={cancelRequest}
                                        cancelToken={cancelToken}
                                        setCancelRequest={setCancelRequest}
                                        sch_type={2}
                                    />
                                </TabPanel>
                            </Fragment>
                        ) : (
                            <AllProducts
                                page="buynow"
                                currency={props.currency}
                                sliderTheme={props.sliderTheme}
                                noTimer={props.noTimer}
                                shippingauction={props.shippingauction}
                                storeTheme={props.storeTheme}
                                redirect_product_view={props.redirect_product_view}
                                pickupCode={props.pickupCode}
                                cancelRequest={cancelRequest}
                                cancelToken={cancelToken}
                                searchbar={props.searchbar}
                                searchbarInputNew={props.searchbarInputNew}
                                sortby={props.sortby}
                                setCancelRequest={setCancelRequest}
                                hideReturn={props?.hideReturn}
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={props.storeTheme === 'Lotsofauctions' ? 3 : 4}>
                        <UserActivity page="make_an_offer_page" />
                        <AllProducts
                            page="make_an_offer"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            noTimer={props.noTimer}
                            shippingauction={props.shippingauction}
                            storeTheme={props.storeTheme}
                            redirect_product_view={props.redirect_product_view}
                            pickupCode={props.pickupCode}
                            checkout_but_pos={props.checkout_but_pos}
                            cancelRequest={cancelRequest}
                            cancelToken={cancelToken}
                            setCancelRequest={setCancelRequest}
                        />
                    </TabPanel>
                </>
            ) : (
                <>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="buyer-dashboard"
                        className="mt-4 liquidationDashTabs"
                    >
                        {global.storeConfigration?.disable_buynow?.value !== '1' &&
                        global.storeConfigration?.need_post_buynow?.value != 0 ? (
                            <Tab label="Buy Now" {...a11yProps(0)} />
                        ) : null}
                        {global.pluginConfiguration?.make_an_offer?.enable ? (
                            <Tab label={'Make An Offer'} {...a11yProps(1)} />
                        ) : null}
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <UserActivity page="dashboard_buynow" />
                        <AllProducts
                            page="buynow"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            noTimer={props.noTimer}
                            shippingauction={props.shippingauction}
                            storeTheme={props.storeTheme}
                            redirect_product_view={props.redirect_product_view}
                            pickupCode={props.pickupCode}
                            theme={props.theme}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <UserActivity page="make_an_offer_page" />
                        <AllProducts
                            page="make_an_offer"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            noTimer={props.noTimer}
                            shippingauction={props.shippingauction}
                            storeTheme={props.storeTheme}
                            redirect_product_view={props.redirect_product_view}
                            pickupCode={props.pickupCode}
                            theme={props.theme}
                        />
                    </TabPanel>
                </>
            )}
        </div>
    )
}

export default MyBidsPanel
