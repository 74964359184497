import React from 'react'
import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import AllCards from '../../../utils/CommonFunctionality/StripeIntegration/AllCards'
import PaymentGateway from '../../../utils/CommonFunctionality/PaymentGateway'

const Cards = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="Cards">
                {global.storeConfigration?.stripe_integration?.value == 1 ? (
                    <AllCards />
                ) : (
                    <PaymentGateway isDepot={true} />
                )}
            </DashboardLayout>
        </Layout>
    )
}

export default Cards
