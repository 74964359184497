import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import * as Yup from 'yup'
import Popup from '../../Product/components/organisms/Popup'
import { mapData } from '../../Product/common/components'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { useContext } from 'react'
import pluginContext from '../../Product/context/plugin/pluginContext'
import alertContext from '../../Product/context/alert/alertContext'
const RequestRefund = ({
    escrow_payment,
    lot_id,
    invoice_id,
    trigger,
    setTrigger,
    seller_refund_accept,
    isrefund_request,
    status,
    refund_request_notes,
    refund_images,
}) => {
    const { requestRefund, responseStatus, clearResponse } = useContext(pluginContext)
    const { setAlert } = useContext(alertContext)
    const [openDispute, setOpenDispute] = useState(false)
    const [loading, setLoading] = useState(false)
    const validationSchema = Yup.object({
        reason: Yup.string().required('Reason is required!'),
    })
    const formik = useFormik({
        initialValues: {
            site_id: global?.storeDetails?.site_id,
            lot_id,
            invoice_id,
            reason: '',
            refund_images: [],
            product_existingimage: [],
        },
        validationSchema,
        onSubmit: (values) => {
            console.log('values', values.refund_images)
            const multipartFormData = new FormData()
            multipartFormData.append('site_id', values.site_id)
            multipartFormData.append('lot_id', values.lot_id)
            multipartFormData.append('invoice_id', values.invoice_id)
            multipartFormData.append('reason', values.reason)
            values.refund_images.forEach((file, index) => {
                multipartFormData.append(`refund_images`, file)
            })
            requestRefund(multipartFormData)
            setLoading(true)
        },
    })

    useEffect(() => {
        if (refund_request_notes) {
            formik.setFieldValue('reason', refund_request_notes)
        } else {
            formik.setFieldValue('reason', '')
        }
        if (refund_images !== undefined && refund_images !== '') {
            const previousImages = refund_images.split(',')
            formik.setFieldValue('product_existingimage', previousImages)
        }
    }, [refund_request_notes, refund_images])

    const fields = [
        {
            label: 'Reason',
            placeholder: 'Enter Your Reason Here...',
            type: 'textarea',
            class: 'col-12 ',
            name: 'reason',
            disabled: refund_request_notes ? true : false,
            formik,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'refund_images',
            titleText: 'Upload Refund Images',
            // innerText: (
            //     <div className="text-center">
            //         <span className="material-icons">note_add</span>
            //         <br />
            //         <p className="mb-2 mt-2">
            //             <b>
            //                 <span>Upload Refund Images</span>
            //             </b>
            //         </p>
            //     </div>
            // ),
            class: refund_request_notes ? 'd-none' : 'col-12 col-sm-12',
            folder: '',
            multiple: true,
            accept: ['image/jpeg', 'image/png'],
            compressImages: false,
        },
        {
            type: 'previousImages',
            formik: formik,
            // reorder: true,
            name: 'product_existingimage',
            class: refund_images ? 'col-md-6 col-12' : 'd-none',
            folder: '',
            hide_delete: refund_request_notes ? 1 : 0,
        },
    ]
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'requestRefund') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message.message, responseStatus.status)
                    formik.resetForm()
                    setOpenDispute(false)
                    setTrigger(!trigger)
                } else {
                    setAlert(responseStatus.message, responseStatus.status)
                }
            }
        }
        clearResponse()
    }, [responseStatus])
    return (
        <div>
            {global?.storeConfigration?.enable_escrow_flow?.value == 1 ? (
                <>
                    {parseInt(seller_refund_accept) == 0 &&
                    parseInt(isrefund_request) == 1 &&
                    status == 'status' ? (
                        <p>Please wait for admin approval.</p>
                    ) : parseInt(seller_refund_accept) == 1 && status == 'status' ? (
                        <p style={{ color: 'green' }}>
                            Your requested accepted by admin.Please send product to seller.
                        </p>
                    ) : parseInt(seller_refund_accept) == 2 && status == 'status' ? (
                        <p style={{ color: 'red' }}>Admin rejected your refund.</p>
                    ) : parseInt(seller_refund_accept) == 3 && status == 'status' ? (
                        <p style={{ color: 'green' }}>Seller Recived Your Product.</p>
                    ) : parseInt(seller_refund_accept) == 4 && status == 'status' ? (
                        <p style={{ color: 'green' }}>Admin Refunded Your Amount.</p>
                    ) : parseInt(seller_refund_accept) == 5 && status == 'status' ? (
                        <p style={{ color: 'red' }}>Seller Requested Product Issues To Admin.</p>
                    ) : parseInt(seller_refund_accept) == 6 && status == 'status' ? (
                        <p style={{ color: 'red' }}>
                            Admin Release Partial Amount To You And Seller.
                        </p>
                    ) : status != 'status' && parseInt(escrow_payment) !== 1 ? (
                        <SecondaryButton
                            label={refund_request_notes ? 'View Return' : 'Return'}
                            onClick={() => setOpenDispute(true)}
                        />
                    ) : (
                        <p>{'-'}</p>
                    )}
                </>
            ) : (
                <SecondaryButton label="Request" onClick={() => setOpenDispute(true)} />
            )}

            <Popup
                open={openDispute}
                handleClose={(e) => setOpenDispute(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Return Request"
            >
                <div className="row justify-content-center requestRefundDesc">
                    {mapData(fields)}
                    {/* {formik.values?.product_existingimage.length > 0
                        ? formik.values?.product_existingimage.map((item) => (
                              <img src={item} alt="img"></img>
                          ))
                        : ''} */}
                    {!refund_request_notes ? (
                        <div className="col-4">
                            <PrimaryButton
                                label={loading ? 'Loading...' : 'Submit'}
                                className="mbv-btn"
                                onClick={formik.handleSubmit}
                                disabled={loading}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </Popup>
        </div>
    )
}

export default RequestRefund
