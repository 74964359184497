import React, { useEffect, useState, useContext, useCallback, useRef } from 'react'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'

const WorkingHours = (props) => {
    const schedule = props?.locations.general
    const locations = props?.locations
    const daysWithSameHours = {}

    const dayAbbreviations = {
        Monday: 'Mon',
        Tuesday: 'Tue',
        Wednesday: 'Wed',
        Thursday: 'Thu',
        Friday: 'Fri',
        Saturday: 'Sat',
        Sunday: 'Sun',
    }
    const isWizardTheme = document.body.classList[1] == 'wizardTheme'
    const formatTime = (time) => {
        let [timePart, period] = time.split(' ')
        let [hours, minutes] = timePart.split(':').map((num) => parseInt(num, 10))

        return minutes === 0
            ? `${hours} ${period.toLowerCase()}`
            : `${hours} ${minutes} ${period.toLowerCase()}`
    }

    schedule.forEach((day) => {
        if (day.Status === 'Open') {
            const timeKey = `${day.Day_Times[0].start_time}-${day.Day_Times[0].end_time}`
            if (!daysWithSameHours[timeKey]) {
                daysWithSameHours[timeKey] = []
            }
            daysWithSameHours[timeKey].push(day.Day)
        }
    })

    const workingHoursParts = []
    const closedDays = []

    Object.entries(daysWithSameHours).forEach(([timeKey, days]) => {
        const [startTime, endTime] = timeKey.split('-')
        const formattedStartTime = formatTime(startTime)
        const formattedEndTime = formatTime(endTime)

        const sortedDays = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ]
            .filter((day) => days.includes(day))
            .map((day) => dayAbbreviations[day])

        const consecutiveGroups = []
        let currentGroup = [sortedDays[0]]

        for (let i = 1; i < sortedDays.length; i++) {
            const prevDayIndex = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].indexOf(
                sortedDays[i - 1],
            )
            const currentDayIndex = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].indexOf(
                sortedDays[i],
            )

            if (currentDayIndex === prevDayIndex + 1) {
                currentGroup.push(sortedDays[i])
            } else {
                consecutiveGroups.push(currentGroup)
                currentGroup = [sortedDays[i]]
            }
        }
        consecutiveGroups.push(currentGroup)

        consecutiveGroups.forEach((group) => {
            if (group.length > 1) {
                workingHoursParts.push(
                    <div className="time">
                        <span className="days">
                            {group[0]} - {group[group.length - 1]} |{' '}
                        </span>
                        <span className="hours">
                            {formattedStartTime} to {formattedEndTime}
                        </span>
                    </div>,
                )
            } else {
                workingHoursParts.push(
                    <div className="time">
                        <span className="days">{group[0]} | </span>
                        <span className="hours">
                            {formattedStartTime} to {formattedEndTime}
                        </span>
                    </div>,
                )
            }
        })
    })

    const allDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    schedule.forEach((day) => {
        if (day.Status !== 'Open') {
            closedDays.push(day.Day)
        }
    })

    const formattedClosedDays = closedDays.map((day) => dayAbbreviations[day]).join(' & ')

    const [showAll, setShowAll] = useState(false)

    const toggleShowAll = () => {
        setShowAll(!showAll)
    }
    const handleOpenMap = () => {
        // const mapAddress = `${locations.business_address1} ${locations.business_address2},${locations.business_city}, ${locations.business_state} ${locations.business_zip},United states`
        const mapAddress = 'Wizard Bins & Auctions'
        const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            mapAddress,
        )}`
        window.open(mapUrl, '_blank')
    }
    return (
        <div className="location-card">
            <div className="location">
                <span className="material-icons">location_on</span>
                <div>
                    <div className="location-text mb-3">
                        <span className="mb-3 d-block">
                            {console.log(locations, 'locations')}
                            {locations.business_city}, {locations.business_state}
                        </span>
                        {locations.business_address1}, {locations.business_address2}
                        <br />
                        {locations.business_city}, VA {locations.business_zip}
                    </div>
                    {workingHoursParts}
                </div>
            </div>
            <div>
                {isWizardTheme ? (
                    <PrimaryButton onClick={handleOpenMap}>Directions</PrimaryButton>
                ) : (
                    <PrimaryButton>Directions</PrimaryButton>
                )}
            </div>
        </div>
    )
}

export default WorkingHours
