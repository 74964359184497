import React from 'react'
import Layout from '../Layout'
const ProductConditions = () => {
    return (
        <Layout>
            <div style={{ padding: '20px', lineHeight: '1.6' }}>
                <h4 style={{ fontWeight: 'bold' }}>Product Conditions:</h4>
                <div className="conditionBox brandConditionBox">
                    <h6
                        style={{
                            fontWeight: 'bold',
                            background: '#51a350',
                            padding: '5px 12px',
                            borderRadius: '4px',
                            color: 'white',
                        }}
                    >
                        Brand New
                    </h6>
                    <p>
                        Item is unused and in the original retail packaging. Packaging may be
                        damaged, but the Item is guaranteed for functionality and completeness.
                        Please refer to item notes and pictures for what you will receive. If item
                        is misrepresented, a refund or credit will be issued at the managers
                        discretion as long as we are notified before you leave the premises. No
                        exceptions.
                    </p>
                </div>
                <div className="conditionBox appearsConditionBox">
                    <h6
                        style={{
                            fontWeight: 'bold',
                            background: '#468df7',
                            padding: '5px 12px',
                            borderRadius: '4px',
                            color: 'white',
                        }}
                    >
                        Appears New
                    </h6>
                    <p>
                        Item may be open box but appears to be in new condition without any major
                        damage or defects. We do our best to examine each item for completeness and
                        functionality, but it is not guaranteed on Appears New items. Please refer
                        to item notes and pictures for what you will receive. We highly suggest
                        using auction preview times to examine the item in person before bidding. No
                        returns, credits, or exchanges on Appears New items.
                    </p>
                </div>
                <div className="conditionBox usedConditionBox">
                    <h6
                        style={{
                            fontWeight: 'bold',
                            background: '#f7cb5f',
                            padding: '5px 12px',
                            borderRadius: '4px',
                        }}
                    >
                        Used
                    </h6>
                    <p>
                        Item shows signs of previous use. Please see notes and pictures for
                        condition details. Although used items should be functional and complete, it
                        is not guaranteed. We highly suggest using auction preview times to examine
                        the item in person before bidding. No returns, credits, or exchanges on used
                        items.
                    </p>
                </div>
                <div className="conditionBox asConditionBox">
                    <h6
                        style={{
                            fontWeight: 'bold',
                            background: '#d8542d',
                            padding: '5px 12px',
                            borderRadius: '4px',
                            color: 'white',
                        }}
                    >
                        As-Is
                    </h6>
                    <p>
                        Item could be damaged, missing parts, or not functional. Item has not been
                        deeply inspected and is sold as is. No returns, credits, or exchanges on as
                        is/for parts only items. Please see notes and pictures for condition
                        details. We highly suggest using auction preview times to examine the item
                        in person before bidding.
                    </p>
                </div>
                <h4 style={{ fontWeight: 'bold' }}>Returns:</h4>
                <div className="conditionnewBox">
                    All purchases are sold as-is. While we strive for accuracy, mistakes can
                    occasionally occur. If an item is severely misrepresented, such as a brand-new
                    item being used and broken, we will issue a refund. Buyers must notify us of any
                    significant discrepancies between the item&apos;s description and its actual
                    condition{' '}
                    <span style={{ textDecoration: 'underline' }}>before leaving the property</span>
                    .
                </div>
                <div className="conditionnewBox">
                    In rare cases, an incorrect photo or description may be linked to an item. If
                    the item received does not match its description, the Buyer must inform staff of
                    the issue{' '}
                    <span style={{ textDecoration: 'underline' }}>before leaving the property</span>
                    .
                </div>
                <div className="conditionnewBox">
                    We are committed to resolving any issues and ensuring your satisfaction; we
                    simply ask that you bring any concerns to our attention prior to leaving. Once
                    the Buyer leaves the property, Bid Auction Depot will not accept returns or
                    address discrepancies related to the item&apos;s description.{' '}
                    {/* <span style={{ textDecoration: 'underline' }}>before leaving the property</span> */}
                </div>
            </div>
        </Layout>
    )
}

export default ProductConditions
