import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import './dashboard.css'
import { ListItem, List, ListItemIcon, Collapse, ListItemText } from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { ExpandLess } from '@material-ui/icons'
import AuthContext from '../../../Product/context/auth/authContext'
import { commonPaths } from '../../../utils/commonFunctions'

const DashboardPanel = ({ title, subtitle, savedcards, seller, invoices }) => {
    const { user } = useContext(AuthContext)
    const checkBeachThemeClassName = document.body.className == 'liquidationTwo beachLiquidation'
    const checkEmursTheme = document.body.className == 'liquidationTwo emursMck'
    const isWizardTheme = document.body.className == 'liquidationTwo wizardTheme'

    const [isAccountOpen, setAccountOpen] = useState(true)
    const [isScheduleOpen, setScheduleOpen] = useState(true)
    const [isInvoicesOpen, setInvoicesOpen] = useState(true)

    return (
        <div className="dashboardLt">
            {isWizardTheme ? (
                <List>
                    <ListItem button>
                        <NavLink
                            activeClassName="active"
                            to={commonPaths.mybids}
                            className="menu-section"
                        >
                            My Bids
                        </NavLink>
                    </ListItem>
                    <ListItem button>
                        <NavLink
                            to="/dashboard/favorites"
                            activeClassName="active"
                            className="menu-section"
                        >
                            My Favorites
                        </NavLink>
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => {
                            setAccountOpen(!isAccountOpen)
                        }}
                    >
                        My Account
                        {isAccountOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={isAccountOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button>
                                <NavLink activeClassName="active" to="/dashboard/profile">
                                    Profile
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <NavLink to="/dashboard/savedSearch" activeClassName="active">
                                    Saved Search
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <NavLink to="/dashboard/cards" activeClassName="active">
                                    Saved Cards
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <NavLink to="/dashboard/preference" activeClassName="active">
                                    Preference
                                </NavLink>
                            </ListItem>
                            {global?.pluginConfiguration?.referral?.enable == 1 ? (
                                <ListItem button>
                                    <NavLink to="/referral" activeClassName="active">
                                        Referral
                                    </NavLink>
                                </ListItem>
                            ) : null}
                        </List>
                    </Collapse>
                    <ListItem
                        button
                        onClick={() => {
                            setScheduleOpen(!isScheduleOpen)
                        }}
                    >
                        Schedule
                        {isScheduleOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={isScheduleOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {user && user.role == 0 && (
                                <ListItem button>
                                    <NavLink to="/dashboard/appointments" activeClassName="active">
                                        Appointments
                                    </NavLink>
                                </ListItem>
                            )}
                            {user && user.role == 0 && (
                                <ListItem button>
                                    <NavLink to="/dashboard/pickups" activeClassName="active">
                                        Pick Ups
                                    </NavLink>
                                </ListItem>
                            )}
                            {user && user.role == 0 && (
                                <ListItem button>
                                    <NavLink to="/dashboard/returns" activeClassName="active">
                                        Returns
                                    </NavLink>
                                </ListItem>
                            )}
                        </List>
                    </Collapse>
                    <ListItem
                        button
                        onClick={() => {
                            setInvoicesOpen(!isInvoicesOpen)
                        }}
                    >
                        Invoices
                        {isInvoicesOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={isInvoicesOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {user && user.role == 0 && (
                                <ListItem button>
                                    <NavLink to="/dashboard/paid_invoices" activeClassName="active">
                                        Paid Invoices
                                    </NavLink>
                                </ListItem>
                            )}

                            {user && user.role == 0 && (
                                <ListItem button>
                                    <NavLink
                                        to="/dashboard/unpaid_invoices"
                                        activeClassName="active"
                                    >
                                        Unpaid Invoices
                                    </NavLink>
                                </ListItem>
                            )}

                            {user && user.role == 0 && (
                                <ListItem button>
                                    <NavLink to="/dashboard/abandoned" activeClassName="active">
                                        Abandoned
                                    </NavLink>
                                </ListItem>
                            )}
                        </List>
                    </Collapse>
                </List>
            ) : (
                <ul>
                    {user && user.role == 1 && (
                        <ListItem button>
                            <NavLink to="/dashboard/overview" activeClassName="active">
                                <span className="material-icons">dashboard</span>
                                Dashboard
                            </NavLink>
                        </ListItem>
                    )}
                    <ListItem button>
                        <NavLink activeClassName="active" to="/dashboard/profile">
                            <span className="material-icons">person</span>
                            My Account
                        </NavLink>
                    </ListItem>

                    <hr className="sdBrDvdr" />
                    <h4 className="sdDvdrHdr">Buyer</h4>
                    <ListItem button>
                        <NavLink activeClassName="active" to={commonPaths.mybids}>
                            <span className="material-icons">gavel</span> My Bids
                        </NavLink>
                    </ListItem>
                    <ListItem button>
                        <NavLink to="/dashboard/favorites" activeClassName="active">
                            <span className="material-icons">favorite</span>
                            My Favorites
                        </NavLink>
                    </ListItem>
                    {invoices && (
                        <ListItem button>
                            <NavLink to="/dashboard/transactions" activeClassName="active">
                                <span className="material-icons">receipt</span>
                                Invoices
                            </NavLink>
                        </ListItem>
                    )}
                    <ListItem button>
                        <NavLink to="/dashboard/savedSearch" activeClassName="active">
                            <span className="material-icons">bookmark</span>
                            Saved Search
                        </NavLink>
                    </ListItem>
                    <ListItem button>
                        <NavLink to="/dashboard/cards" activeClassName="active">
                            <span className="material-icons">credit_card</span>
                            Saved Cards
                        </NavLink>
                    </ListItem>
                    {global?.pluginConfiguration?.ach_stripe?.enable == 1 ? (
                        <ListItem button>
                            <NavLink to="/dashboard/ach" activeClassName="active">
                                <span className="material-icons">account_balance</span>
                                Saved Bank Details
                            </NavLink>
                        </ListItem>
                    ) : null}
                    <ListItem button>
                        <NavLink to="/dashboard/preference" activeClassName="active">
                            <span className="material-icons">notifications</span>
                            Preference
                        </NavLink>
                    </ListItem>
                    {global?.pluginConfiguration?.referral?.enable == 1 ? (
                        <ListItem button>
                            <NavLink to="/referral" activeClassName="active">
                                <span className="material-icons">share</span>
                                Referral
                            </NavLink>
                        </ListItem>
                    ) : null}

                    {user && user.role == 1 && (
                        <>
                            <hr className="sdBrDvdr" />
                            <h4 className="sdDvdrHdr">Seller</h4>
                            <ListItem button>
                                <NavLink activeClassName="active" to="/sellerdashboard">
                                    <span className="material-icons">store</span> My Auctions
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <NavLink activeClassName="active" to={commonPaths.mylots}>
                                    <span className="material-icons">store</span> My Lots
                                </NavLink>
                            </ListItem>
                            {/* <ListItem button>
                            <NavLink activeClassName="active" to="/dashboard/buynow">
                                <span className="material-icons">shopping_basket</span> My Buynow
                            </NavLink>
                        </ListItem> */}
                        </>
                    )}
                    {user && user.role == 1 && (
                        <ListItem button>
                            <NavLink to="/dashboard/invoices" activeClassName="active">
                                <span className="material-icons">receipt</span>
                                Invoices
                            </NavLink>
                        </ListItem>
                    )}
                    {user && user.role == 0 && (
                        <ListItem button>
                            <NavLink to="/dashboard/transactions" activeClassName="active">
                                <span className="material-icons">receipt</span>
                                Transactions
                            </NavLink>
                        </ListItem>
                    )}

                    {user && user.role == 1 && (
                        <ListItem button>
                            <NavLink to="/dashboard/reports" activeClassName="active">
                                <span className="material-icons">description</span>
                                Reports
                            </NavLink>
                        </ListItem>
                    )}
                    {/* {user && user.role == 1 && (
                    <ListItem button>
                        <NavLink to="/dashboard/photouploader" activeClassName="active">
                            <span className="material-icons">collections</span>
                            Photo Uploader
                        </NavLink>
                    </ListItem>
                )} */}
                    {checkBeachThemeClassName || checkEmursTheme || isWizardTheme ? (
                        ''
                    ) : (
                        <>
                            {global.storeConfigration?.remove_message_module?.value != 1 ? (
                                <ListItem button>
                                    <NavLink to="/dashboard/message" activeClassName="active">
                                        <span className="material-icons">question_answer</span>
                                        Message
                                    </NavLink>
                                </ListItem>
                            ) : null}
                        </>
                    )}
                    {/* {user && user.role == 1 && (
                    <ListItem button>
                        <NavLink to="/onboard" activeClassName="active">
                            <span className="material-icons">payments</span>
                            Payment Gateway Onboard
                        </NavLink>
                    </ListItem>
                )} */}
                </ul>
            )}
        </div>
    )
}

export default withRouter(DashboardPanel)
