import React, { useReducer } from 'react'
import PayContext from './directStripeContext'
import PayReducer from './directStripeReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    PAYMENT_WITH_CARD,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    ADD_USER_CARD,
    GET_USER_CARD,
    DELETE_USER_CARD,
} from './directStripeTypes'
import { DirectAPICAll } from '../../common/components'
import { getBase64 } from '../../../utils/commonFunctions'

const DirectStripeState = (props) => {
    const initialState = {
        responseStatus: null,
        usercards: [],
    }

    const [state, dispatch] = useReducer(PayReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    // Get all user cards
    const getStripeCard = async (formData) => {
        try {
            formData.mode = process.env.REACT_APP_PAYMENTMODE
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'getCustomerDetails',
                    formData,
                    '',
                    'plugin/payment_geteway/own-stripe/customer',
                ),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_USER_CARD,
                    payload: res.data.data.message.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: [],
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Delete a stripe card
    const deleteStripeCard = async (formData) => {
        try {
            const from = 'deleteStripeCard'
            formData.mode = process.env.REACT_APP_PAYMENTMODE
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'deleteCardDetails',
                    formData,
                    '',
                    'plugin/payment_geteway/own-stripe/customer',
                ),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    { status: 'success', message: 'Card Deleted Successfully!' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: 'Something went wrong!' }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    // Add a new card
    const addNewCard = async (formData) => {
        try {
            const from = 'addNewCard'
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'createCustomerDetails',
                    formData,
                    '',
                    'plugin/payment_geteway/own-stripe/customer',
                ),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    { status: 'success', message: 'Card Added Successfully!' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: 'Something went wrong!' }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    // Upload Identity Proof
    const uploadIdentityProof = async (files, from = 'uploadIdentityProof', user_id, site_id) => {
        try {
            const data = new FormData()
            data.append('file', files[0])
            data.append('purpose', 'identity_document')
            var key = global.storeConfigration?.public_key?.value
            const config = {
                headers: {
                    Authorization: 'Bearer ' + key,
                    'content-type': 'multipart/form-data',
                },
                cache: false,
                contentType: false,
                processData: false,
            }
            const results = await DirectAPICAll(
                'post',
                'https://uploads.stripe.com/v1/files',
                data,
                '',
                config,
            )
            if (results.data.id) {
                getBase64(files[0], async (result) => {
                    const [res] = await Promise.all([
                        apiCall(
                            'post',
                            'imageupload',
                            { imageBinary: result, user_id, site_id },
                            '',
                            'plugin/payment_geteway/own-stripe/bank',
                        ),
                    ])
                    if (res.data.success === 'yes') {
                        resp.commonResponse(
                            {
                                status: 'success',
                                message: `${
                                    from === 'front_id' ? 'Front' : 'Back'
                                } image uploaded successfully!`,
                                responseData: {
                                    image_id: results.data.id,
                                    image_name: res.data.image,
                                },
                            },
                            from,
                        )
                    } else {
                        resp.commonResponse(
                            { status: 'error', message: 'Something went wrong!' },
                            from,
                        )
                    }
                })
            } else {
                resp.commonResponse({ status: 'error', message: 'Something went wrong!' }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    // Create Bank Account
    const createBankAccount = async (formData) => {
        try {
            const from = 'createBankAccount'
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'createBankDetails',
                    formData,
                    '',
                    'plugin/payment_geteway/own-stripe/bank',
                ),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    { status: 'success', message: 'Account Created Successfully!' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const createPaystackBankAccount = async (formData) => {
        const from = 'createPaystackBankAccount'
        try {
            const res = await DirectAPICAll(
                'post',
                process.env.REACT_APP_BASE_URL + 'auctionpay/onboardseller',
                formData,
            )
            if (res.data.status) {
                resp.commonResponse(
                    { status: 'success', message: 'Account Created Successfully!' },
                    from,
                )
            } else {
                resp.commonResponse(
                    {
                        status: 'error',
                        message: res.data.data ? res.data.data : `Something Went Wrong!`,
                    },
                    from,
                )
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <PayContext.Provider
            value={{
                responseStatus: state.responseStatus,
                usercards: state.usercards,
                getStripeCard,
                deleteStripeCard,
                addNewCard,
                uploadIdentityProof,
                createBankAccount,
                createPaystackBankAccount,
                clearResponse,
            }}
        >
            {props.children}
        </PayContext.Provider>
    )
}

export default DirectStripeState
