import React, { useReducer } from 'react'
import UserContext from './userContext'
import UserReducer from './userReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    EDIT_PROFILE,
    GET_EDIT_PROFILE,
    CHANGE_PWD,
    GET_PFR,
    GET_MSG_USER_LIST,
    GET_BLOG_LIST,
    GET_SINGLE_BLOG,
    GET_ONE_MSG_USER,
    GET_STATIC_PAGE,
    CLEAR_RESPONSE,
    UPDATE_USER_DYN_PROFILE,
    GET_ALL_SHIPPING_ADDRESS,
    ADD_SHIPPING_ADDRESS,
    DELETE_SHIPPING_ADDRESS,
    RESPONSE_STATUS,
    GET_SELLER_LIST,
    GET_LOCATION,
    SUBSCRIBE_TO_NEWS,
} from './userTypes'

const UserState = (props) => {
    const initialState = {
        buyerseller_messages: [],
        preference: [],
        blogposted: {
            results: [],
            total_pagecnt: 0,
            total_only: 0,
        },
        blogcontent: {},
        userlist_messages: {
            results: [],
            total_pagecnt: 0,
            total_only: 0,
        },
        static_page: {
            content: '',
        },
        edit_profile: {},
        all_shipping_address: [],
        seller_list: { data: [], total: 0, type: '' },
        responseStatus: null,
        countryValues: {},
        subscribe_to_news: {},
    }

    const [state, dispatch] = useReducer(UserReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)
    // const updateProfile = async formData => {
    //   console.log('updating profile',formData);
    //   try {
    //     const [res] = await Promise.all([
    //       apiCall('post', 'updateUserProfile', formData, 'formdata')
    //     ]);
    //     if (res.data.status === 'yes') {
    //       dispatch({
    //         type: EDIT_PROFILE,
    //         payload: res.data.message
    //       });
    //     } else {
    //       dispatch({
    //         type: RESPONSE_STATUS,
    //         payload: res.data.failedreason
    //       });
    //     }
    //   } catch (err) {
    //     dispatch({
    //       type: RESPONSE_STATUS,
    //       payload: err.response.data
    //     });
    //   }
    // };

    const editProfile = async (formData) => {
        const from = 'editProfile'
        try {
            const [res] = await Promise.all([apiCall('post', 'updateUserProfile', formData, '')])
            if (res.data.status === 'yes') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: formData.become_seller
                            ? 'Request Submitted Successfully'
                            : 'Profile updated successfully',
                    },
                    from,
                )
                return res
            } else {
                resp.commonResponse({ status: 'error', message: res.data.failedreason }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const subscribeToNews = async (formData) => {
        try {
            const from = 'subscribeToNews'
            const [res] = await Promise.all([apiCall('post', 'mailchimp-subscription', formData)])
            if (res.data.success) {
                resp.commonResponse({ status: 'success', message: res.data.message }, from)
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            dispatch({
                type: SUBSCRIBE_TO_NEWS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updateUserProfileDynamic = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'updateUserDetails', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: UPDATE_USER_DYN_PROFILE,
                    payload: 'user_updated_successfull',
                    // payload: res.data.data.result
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getEditProfile = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getUserDetails', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: GET_EDIT_PROFILE,
                    payload: res.data.data,
                    // payload: res.data.data.result
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const changeForgotPassword = async (formData) => {
        const from = 'changeForgotPassword'
        try {
            const [res] = await Promise.all([apiCall('post', 'change-password', formData)])
            if (res.data.success === 'yes') {
                resp.commonResponse(
                    { status: 'success', message: 'Password changed successfully' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.reason }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const forgotPassword = async (formData) => {
        const from = 'forgotPassword'
        try {
            const [res] = await Promise.all([apiCall('post', 'forgot_password', formData)])
            if (res.data.success === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Email sent successfully' }, from)
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getpreference = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getnotification', formData)])
            if (res.data.success === 'yes') {
                dispatch({
                    type: GET_PFR,
                    payload: res.data.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.successreason ? res.data.successreason : res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updatepreference = async (formData) => {
        const from = 'updatepreference'
        try {
            const [res] = await Promise.all([apiCall('post', 'updatenotification', formData)])
            if (res.data.success === 'yes') {
                resp.commonResponse(
                    { status: 'success', message: 'Preference Successfully updated' },
                    from,
                )
            } else {
                resp.commonResponse(
                    {
                        status: 'error',
                        message: res.data.successreason ? res.data.successreason : res.data.message,
                    },
                    from,
                )
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const changepwd = async (formData) => {
        const from = 'changepwd'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updatepassword_in_profile', formData),
            ])
            if (res.data.success === 'yes') {
                resp.commonResponse(
                    { status: 'success', message: 'Password changed successfully' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const addNewBlogPost = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'insertBlogPost', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'blog_successfullysend',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'blog_notsend',
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'blog_notsend',
            })
        }
    }

    const getOneBlog = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'get_blogPost', formData)])
            if (res.data.success === 'yes') {
                dispatch({
                    type: GET_SINGLE_BLOG,
                    payload: res.data.results,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getBlogDetails = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'list_BlogPagination', formData)])
            if (res.data.success === 'yes') {
                dispatch({
                    type: GET_BLOG_LIST,
                    payload: res.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const sendUserMessages = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'insertMessages', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'message_successfullysend',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'message_notsend',
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'message_notsend',
            })
        }
    }

    const getUserMessages = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'list_buyerPagination', formData)])
            if (res.data.success === 'yes') {
                dispatch({
                    type: GET_MSG_USER_LIST,
                    payload: res.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getOneUserBuyerMessages = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'get_chathistory', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: GET_ONE_MSG_USER,
                    payload: res.data.data,
                })
            } else if (res.data.status === 'no') {
                dispatch({
                    type: GET_ONE_MSG_USER,
                    payload: [],
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Register User
    const careerForm = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'send_careerform', formData, 'formdata'),
            ])
            if (res.data.success) {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'Career successful',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.error,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Register User
    const sellingForm = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'send_sellingform', formData)])
            if (res.data.success) {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'Seller successful',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.error,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Register User
    const contactUs = async (formData) => {
        try {
            formData.is_send_email = 1
            const [res] = await Promise.all([apiCall('post', 'send_contactus', formData)])
            if (res.data.success) {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'Contact successful',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.error,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const send_contactus = async (formData) => {
        const from = 'send_contactus'
        formData.is_send_email = 1
        try {
            const [res] = await Promise.all([apiCall('post', 'send_contactus', formData, '', '')])
            if (res.data.success === true) {
                resp.commonResponse(
                    { status: 'success', message: 'Message submitted successfully' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.error }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const upload_buyer_document = async (formData) => {
        const from = 'upload_buyer_document'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'upload_buyer_document', formData, '', ''),
            ])
            if (res.data.status) {
                resp.commonResponse({ status: 'success', message: 'Uploaded successfully!' }, from)
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const termsCheckUpdate = async (formData) => {
        const from = 'termsCheckUpdate'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'terms_check_update', formData, '', ''),
            ])
            if (res.data.success) {
                resp.commonResponse({ status: 'success', message: 'Accepted!' }, from)
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.error }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getStaticPage = async (formData, hide_else_msg = 0) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'show_static_content', formData)])
            if (res.data.status === 'true') {
                dispatch({
                    type: GET_STATIC_PAGE,
                    payload: res.data.data[0],
                    // payload: res.data.data.result
                })
            } else {
                dispatch({
                    type: GET_STATIC_PAGE,
                    payload: '',
                    // payload: res.data.data.result
                })
                if (!hide_else_msg) {
                    dispatch({
                        type: RESPONSE_STATUS,
                        payload: res.data.message,
                    })
                }
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllShippingAddress = async (body = '') => {
        try {
            const [res] = await Promise.all([apiCall('get', 'get_all_shipping_address', body)])
            if (res.data.success) {
                dispatch({
                    type: GET_ALL_SHIPPING_ADDRESS,
                    payload: res.data.result,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const add_shipping_address = async (formData) => {
        try {
            const from = 'add_shipping_address'
            const [res] = await Promise.all([apiCall('post', 'add_shipping_address', formData)])
            if (res.data.success) {
                resp.commonResponse({ status: 'success', message: res.data.message }, from)
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const emailverification = async (formData) => {
        const from = 'user_emailverification'
        try {
            const [res] = await Promise.all([apiCall('get', `${formData}`, {}, {}, 'activate')])
            if (res.data.success === true) {
                resp.commonResponse(
                    { status: 'success', message: 'Account Activated Successfully.' },
                    from,
                )
            } else {
                resp.commonResponse(
                    {
                        status: 'error',
                        message: res.data.message ? res.data.message : 'api disabled',
                    },
                    from,
                )
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const follow = async (formData, setFollowed = 1) => {
        try {
            const from = 'follow'
            const [res] = await Promise.all([apiCall('post', 'follow', formData)])
            if (res.data.success) {
                resp.commonResponse({ status: 'success', message: res.data.message }, from)
                setFollowed(parseInt(formData.follow) == 1 ? 0 : 1)
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSellerList = async (formData, cancelToken) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSellersList', formData, '', '', cancelToken),
            ])
            if (res.data.success) {
                dispatch({
                    type: GET_SELLER_LIST,
                    payload: { ...res.data.result, type: formData.type },
                })
            } else {
                dispatch({
                    type: GET_SELLER_LIST,
                    payload: { data: [], total: 0, type: formData.type },
                })
            }
        } catch (err) {
            console.log('error', err)
            // dispatch({
            //     type: RESPONSE_STATUS,
            //     payload: 'Something went wrong!',
            // })
        }
    }

    const reportSeller = async (formData) => {
        try {
            const from = 'reportSeller'
            const [res] = await Promise.all([apiCall('post', 'reportSeller', formData)])
            if (res.data.success) {
                resp.commonResponse({ status: 'success', message: res.data.message }, from)
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getLocation = async (countryCode) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'fetchLocation', countryCode)])
            if (res.data.success) {
                dispatch({
                    type: GET_LOCATION,
                    payload: res.data.country,
                })
            } else {
                dispatch({
                    type: GET_LOCATION,
                    payload: res.data.message,
                })
            }
            return data
        } catch (error) {
            // console.log('comon fetch location error =>', error)
        }
    }
    const unRegisterUserSubscribe = async (formData) => {
        const from = 'unRegisterUserSubscribe'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'mailchimp_subscription_new', formData),
            ])
            if (res.data.success == true) {
                dispatch({
                    type: GET_LOCATION,
                    payload: res.data,
                })
                resp.commonResponse({ status: 'success', message: res.data.message }, from)
            } else {
                dispatch({
                    type: GET_LOCATION,
                    payload: res,
                })
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (error) {
            resp.commonResponse({ status: 'error', message: res.data.message }, from)
            console.error(error)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <UserContext.Provider
            value={{
                // profile: state.profile,
                responseStatus: state.responseStatus,
                editProfile,
                clearResponse,
                updateUserProfileDynamic,
                subscribeToNews,

                getUserMessages,
                getBlogDetails,
                getOneBlog,
                getOneUserBuyerMessages,
                sendUserMessages,
                addNewBlogPost,
                follow,
                getSellerList,
                reportSeller,
                // updateProfile,
                edit_profile: state.edit_profile,
                subscribe_to_news: state.subscribe_to_news,
                preference: state.preference,
                static_page: state.static_page,
                userlist_messages: state.userlist_messages,
                blogcontent: state.blogcontent,
                blogposted: state.blogposted,
                buyerseller_messages: state.buyerseller_messages,
                all_shipping_address: state.all_shipping_address,
                seller_list: state.seller_list,
                countryValues: state.countryValues,
                getEditProfile,
                changepwd,
                unRegisterUserSubscribe,
                updatepreference,
                getpreference,
                forgotPassword,
                changeForgotPassword,
                getStaticPage,
                contactUs,
                sellingForm,
                send_contactus,
                careerForm,
                // viewProfile
                getAllShippingAddress,
                add_shipping_address,
                emailverification,
                getLocation,
                termsCheckUpdate,
                upload_buyer_document,
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserState
